import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {CookieBanner} from "@palmabit/react-cookie-law";

const Footer = () => {
    return (
        <div>
            <CookieBanner
                message="This website uses cookies to enhance the user experience"
                wholeDomain={true}
                managePreferencesButtonText="Cookie settings"
                onAccept={() => {
                }}
                onAcceptPreferences={() => {
                }}
                onAcceptStatistics={() => {
                }}
                onAcceptMarketing={() => {
                }}
                styles={{
                    dialog: {
                        backgroundColor: 'rgb(0, 0, 50)',
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        padding: '0 10%'
                    },
                    container: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    },
                    message: {
                        color: 'rgb(255, 255, 255)',
                        display: 'block',
                        padding: '10px'
                    },
                    policy: {
                        display: 'none'
                    }
                }}
            />
        </div>
    );
};

export default Footer;
