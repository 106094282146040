import React from "react";
import styled from 'styled-components';
import imageL from '../img/CAR_YELLOW.png';
import imageS from '../img/yell.png';

const ImageStyled = styled.div`
  background-color: #ffcc00;
  background-image:url('${imageS}');
  position: fixed;
  background-size: cover;
  background-position: left;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media (min-width: 768px) {
    background-image: url('${imageL}');
    background-position: center;
  }
`;

export default () => <ImageStyled />;