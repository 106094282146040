import React, {  useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import * as firebase from '../firebase';
import { toast} from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';




const Password = ({ history }) => {

    let userr = firebase.auth.currentUser;




  const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState("");
    const [newpass, setNewpass] = useState("");
    const [confirm, setConfirm] = useState("");
    const [complete, setComplete] = useState(false);
    const { user } = useSelector((state) => ({ ...state }));
    let [color, setColor] = useState("#ffcc00");
    let [type, setType] = useState("bars");



  useEffect(() => {
    if (!user) history.push('/');
}, [user]);

    async function currentpasscheck() {
        try {
            const email = user.email;

            const result = await firebase.auth.signInWithEmailAndPassword(email, current);
            return result;
        } catch (err) {
            return ("invlaid..");
        }
}

  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);


      const results = await currentpasscheck();
      if (results === "invlaid..") {

          toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Invalid current password</div></div>,

            {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
            });
          setLoading(false);
      } else {
              if (newpass === confirm) {
                  try {
                      userr.updatePassword(newpass).then(() => {
                          // Update successful.

                          setLoading(false);
                          setComplete(true);
                      }, (error) => {
                          // An error happened.


          toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">{error.message}</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });
                          setLoading(false);
                      });
                  }
                  catch (err) {

                  }
              }
        else {
          toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Invalid Current Password</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });
          }
      }

  }

    return (
        <>
        { loading ? (
        <ReactLoading className="loading-screen" type={type} color={color} height={667} width={375} />
        ) : (<></>)}
        <div className="logincontain">
           {complete === false && (
           <>
           <div class="page-header">
              <h1>Password Change</h1>
           </div>
           <form onSubmit={handleSubmit}>
              <div className="form-group grey-input">
                 <label>Current Password</label>
                 <input type="password"
                    name='email'
                    value={current}
                    onChange={(e) => setCurrent(e.target.value)}
                 autoFocus
                 className="form-control"
                 />
              </div>
              <div className="form-group grey-input">
                 <label>New Password</label>
                 <input type="password"
                    name='email'
                    value={newpass}
                    onChange={(e) => setNewpass(e.target.value)}

                 className="form-control"
                 />
              </div>
              <div className="form-group grey-input">
                 <label>Confirm Password</label>
                 <input type="password"
                    name='email'
                    value={confirm}
                    onChange={(e) => setConfirm(e.target.value)}

                 className="form-control"
                 />
              </div>
              <div className="row">
                 <div className="col-lg">
                    <div className="i-logo"></div>
                    <p className="i-text">For a strong password, we suggest using numbers, special characters and capital letters in your password.</p>
                 </div>
              </div>
              <input  type="submit" value="Submit Change" className="btn btn-info" disabled={newpass !== confirm}
                 />
           </form>
           </>
           )}
           {complete && (
           <>
           <div className="background-password">
              <div className="password-changed-logo"></div>
           </div>
           <h1 className="background-password"> Your password has been changed</h1>
           </>
           )}
        </div>
        </>
  );
}

export default Password;