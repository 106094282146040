import React, {  useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector} from 'react-redux';
import '../../App.css';
import { loadStripe } from '@stripe/stripe-js';
import { toast as toastmain } from 'react-toastify';
import { toast as toasty } from 'react-hot-toast';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';
import { useMediaQuery } from 'react-responsive';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51IGMioEIJjMRbuQ3C4yOU9Tz0pXnnrU3HGcD8tYewALqn1IpZRGI2W9HvjbYI72TxGRVDJpsZiuxbULTbNnr7jl100dyQXXm5X');

const Vdi = ({ history }) => {
    let { user, vehicle } = useSelector((state) => ({ ...state }));

    if (!vehicle || !user) {
        history.goBack();
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 600px)'
    })
    const dispatch = useDispatch();
    const [reg, setReg] = useState("");
    const [vehicleFullCheck, setVehicleFullCheck] = useState(null);
    const [loading, setLoading] = useState(false);
    const color = "#ffcc00";
    const type = "bars";

    useEffect(() => {
        async function getAdminData () {
            try {
                const body = await axios.get(`${process.env.REACT_APP_API}api/vehicle/admin`);
            }
            catch (err) {

            }
        }

        if (vehicle && vehicle.fullcheck) {
            setVehicleFullCheck(vehicle.fullcheck);
        }

        if (vehicle && vehicle.vehicleRegistration) {
            setReg(vehicle.vehicleRegistration);
        }

        getAdminData();
    }, []);

    async function checkExistingHc(){
        try{
            const email = user.email;
            const body = { vehicleRegistration: reg };
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const result = await axios.post(`${process.env.REACT_APP_API}api/vdi/user/` + email, body, config);
      
            if (result.data === "") {
                apicall();
            } else {
                toastPop();
            }
        }
        catch (err) {

        }
    }

    const renderErrorMessage = (message) => {
        return (
            <div>
                <div className="car-contain">
                    <div className="car-img-error" />
                </div>
                <div className="car-img-text">
                    {message}
                </div>
            </div>
        );
    }

    const renderError = (message) => {
        toastmain.error(renderErrorMessage(message),{
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    const renderSuccessMessage = (message) => {
        return toastmain.success(
            <div>
                <div className="car-contain">
                    <div className="car-img-success" />
                </div>
                <div className="car-img-text">
                    {message}
                </div>
            </div>, {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
    }

    async function apicall() {
        setLoading(true);

        if (user.checks > 0) {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                const User = user.email;
                const body = JSON.stringify({ User });
                const response = await axios.post(`${process.env.REACT_APP_API}api/vdi/` + reg, body, config);

                if (response.data.Response.StatusMessage === "Trial account limitation: Key value for VRM must contain the letter 'A'") {
                    renderError('Sandbox letter A issue.. This message will not exist when using full version');
                    setLoading(false);
                }

                if (response.data.Response.StatusCode === "Failure") {
                    renderError(`Sorry, we can't provide a history check for that registration number. Often this is because of a recent number plate change`);
                    setLoading(false);
                } else {
                    if (response !== 'INVALID') {
                        if (response.data.Response.StatusMessage !== "Success") {
                            if (response.data.Response.StatusCode === "ItemNotFound") {
                                setVehicleFullCheck(null);
                                renderError(`Sorry, we can't find that registration number on the history check database`);
                            } else if (response.data.Response.StatusCode === "SandboxLimitation") {
                                setVehicleFullCheck(null);
                                renderError("Sandbox Limitation (No A in Reg");
                            } else if (response.data.hasOwnProperty("Response") === false) {
                                setVehicleFullCheck(null);
                                renderError("Empty");
                            } else {
                                setVehicleFullCheck(null);
                                renderError(response.data.Response.StatusCode);
                            }
                            setLoading(false);
                        } else {
                            setVehicleFullCheck(response.data.Response.DataItems);
                            delCredit();
                            setLoading(false);
                            renderSuccessMessage(`Here is your history check`);
                        }
                    }
                }
            } catch (err) {
                setVehicleFullCheck(null);
                setLoading(false);
            }
        } else {
            renderError(`No available credits, please purchase some before trying again`);
            history.push("/checkout")
        }

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            const _id = await gettingCar();
            const email = user.email;
            const body = JSON.stringify({ _id, email });
            await axios.post(`${process.env.REACT_APP_API}api/shortlist/add`, body, config);
        }
        catch (err) {

        }
    }




  async function gettingCar() {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const newVehicles = {
      vehicleRegistration: reg
    }

    const body = JSON.stringify( newVehicles );

    const res = await axios.post(`${process.env.REACT_APP_API}api/vehicle/score`, body, config);

    return res.data.toUpperCase();
    }

    async function delCredit() {

        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const body = ({ });

          try {
            const res = await axios.post(`${process.env.REACT_APP_API}api/update/credit/` + user.email , body, config);

              var updat = await axios.get(`${process.env.REACT_APP_API}api/user/` + user.email);

              dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                  email: user.email,
                  displayName: user.displayName,
                    phoneNumber: user.phoneNumber,
                    marketingAuth: user.marketingAuth,
                    checks: updat.data.checks
                  // firstName: body.data.firstName || user.email.split('@')[0],
                //   token: idTokenResult.token,
                }
              })
          } catch (error) {

          }

    }


    function confirmYes() {
        toasty.dismiss();
        apicall();
    }

    function confirmNo(){
        toasty.dismiss();
    }

    function toastPop() {
        toasty(
            <div>
                <div className="car-logo-confirm-container">
                    <div className="car-logo-confirm"/>
                </div>
                <div>
                    <div className="car-img-text">
                        You have already completed a History Check on this car. Do you want another
                        one?
                        <div className="btns">
                            <div className="btn-yes" type="button"
                                 onClick={() => (confirmYes())}>Yes
                            </div>
                            <div className="btn-no" type="button" onClick={() => (confirmNo())}>No
                            </div>
                        </div>
                    </div>
                </div>
            </div>, {duration: 500000});
    }


    const renderButton = () => {
        return (
        <div className="reg-button-cont">
            <div onClick={checkExistingHc} type="button" className="reg-button">
                Get Report
            </div>
        </div>
        );
    }

    const renderLoading = () => {
        return (<ReactLoading className="loading-screen" type={type} color={color} height={667} width={375} />)
    }

    const renderPassIcon = (ifPass) => {
        return ifPass ? (<div className="tick-logo"/>) : (<div className="cross-logo"/>);
    }

    const renderUserScoreInfo = () => {
        return (
            <div>
                <h2>History Check </h2>
                <h3>Current Credits: {user.checks}</h3>
            </div>
        )
    }

    const renderRegNumberForm = () => {
        const placeholder = vehicle && vehicle.fullcheck ? vehicle.fullcheck : '';

        return (
            <form onSubmit={e => apicall(e)}>
                <div className="form-group">
                    <div className="reg-plate-yellow">
                        <input type="text"
                               autoComplete="off"
                               placeholder={placeholder}
                               name='vehiclesRegistration'
                               value={reg.toUpperCase()}
                               onChange={(e) => setReg(e.target.value)}
                               required
                               maxLength="8"
                        />
                    </div>
                    {renderButton()}
                </div>
            </form>
        )
    }

    const renderOverallResult = () => {
        const ifPass = vehicleFullCheck.OverallResult === 'Pass';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h3 className="vehicle-info-left bottom-border-mobile">Vehicle Report</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left">Make: {vehicleFullCheck.Make}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left">Model: {vehicleFullCheck.Model}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left">Registration Number: {vehicleFullCheck.Vrm}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left"> Report Type:
                                    {vehicleFullCheck.OverallResult === 'Pass' ? (<div className="pass-green inline">PASS</div>) : (<div className="fail-red inline">FAIL</div>)}
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left">Report issued on: {vehicleFullCheck.stamp} </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderFinanceRecordResult = () => {
        const ifPass = vehicleFullCheck.FinanceRecordResult === 'Pass';
        const textColor = ifPass ? 'pass-green' : 'fail-red';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className={`vehicle-info-left bottom-border ${textColor}`}>
                                    Finance [{vehicleFullCheck.FinanceRecordResult}]
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="vehicle-info-left card-font-small">
                                    <strong>This car has {vehicleFullCheck.FinanceRecordCount} agreement(s) outstanding </strong>
                                    {(vehicleFullCheck.FinanceRecordCount > 0) && (
                                        <div>
                                            <p>Finance List: </p>
                                            <div className="finance-info-left">
                                                <p className="">----------------</p>
                                                {vehicleFullCheck.FinanceRecordList.map((f, i) => (
                                                    <div key={`FinanceRecordList_${i}`}>
                                                        <p className="">Vehicle Desc: {f.VehicleDescription}</p>
                                                        <p className="">Agreement Type: {f.AgreementType}</p>
                                                        <p className="">Start Date: {f.AgreementDate}</p>
                                                        <p className="">Agreement Term(months): {f.AgreementTerm}</p>
                                                        <p className="">Agreement Number: {f.AgreementNumber}</p>
                                                        <p className="">Finance Company: {f.FinanceCompany}</p>
                                                        <p className="">Agreement Contact: {f.ContactNumber}</p>
                                                        <p className="">----------------</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    <strong>This car has {vehicleFullCheck.HighRiskRecordCount} High risk vehicle flags(s) </strong>
                                    {(vehicleFullCheck.HighRiskRecordList ) ? (
                                        vehicleFullCheck.HighRiskRecordList.map((f, i) => (
                                            <div key={`HighRiskRecordList_${i}`}>
                                                <p className="margin-left">----------------</p>
                                                <p className="">High risk type: {f.HighRiskType}</p>
                                                <p className="">Date of interest: {f.DateOfInterest}</p>
                                                <p className="">Period of time(months): {f.Period}</p>
                                                <p className="">Contact number: {f.ContactNumber}</p>
                                            </div>
                                        ))
                                    ): ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderWriteOffRecordCount = () => {
        const ifPass = vehicleFullCheck.WriteOffResult === 'Pass';
        const textColor = ifPass ? 'pass-green' : 'fail-red';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                <div className="col-sm">
                    <div className="row">
                        <div className="col-sm">
                            <h5 className={`vehicle-info-left bottom-border ${textColor}`}>
                                Condition [{vehicleFullCheck.WriteOffResult}]
                            </h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="vehicle-info-left card-font-small">
                                <strong>This car has {vehicleFullCheck.WriteOffRecordCount} write-off marker(s)</strong>
                                    {vehicleFullCheck.WriteOffRecordCount > 0 && (
                                    <div>
                                        <p className="margin-left">----------------</p>
                                        {vehicleFullCheck.WriteOffRecordList.map((w, i) => (
                                            <div key={`WriteOffRecordList_${i}`}>
                                                <p className="margin-left">Date: {w.LossDate}</p>
                                                <p className="margin-left">Write-off category: {w.Category}</p>

                                                <p className="margin-left">Type of Write-off: {w.LossType}</p>
                                                <p className="margin-left">{w.MiaftrEntryDate}</p>

                                                <p className="margin-left">----------------</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        );
    }

    const renderScrapResult = () => {
        const ifPass = vehicleFullCheck.ScrapResult === 'Pass';
        const textColor = ifPass ? 'pass-green' : 'fail-red';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className={`vehicle-info-left bottom-border ${textColor}`}>
                                    Scrapped [{vehicleFullCheck.ScrapResult}]
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="vehicle-info-left card-font-small">
                                    { ((vehicleFullCheck.HighRiskRecordList !== null && vehicleFullCheck.Scrapped === null) || vehicleFullCheck.Scrapped === false) ?
                                        (<strong>This car has not been scrapped</strong>):
                                        (<div>
                                            <strong>This car has been scrapped</strong>
                                            <p>Date Scrapped: {vehicleFullCheck.ScrapDate}</p>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderImportExportResult = () => {
        const ifPass = vehicleFullCheck.ImportExportResult === 'Pass';
        const textColor = ifPass ? 'pass-green' : 'fail-red';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className={`vehicle-info-left bottom-border ${textColor}`}>
                                    Import/Export [{vehicleFullCheck.ImportExportResult}]
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="vehicle-info-left card-font-small">
                                    { ((vehicleFullCheck.HighRiskRecordList !== null && vehicleFullCheck.Imported === null) || vehicleFullCheck.Imported === false) ? (
                                        <strong>This car has not been imported</strong>
                                    ) : (<div>
                                            <strong>This car HAS been imported</strong>
                                            <p>Import Date: {vehicleFullCheck.ImportDate} </p>
                                        </div>
                                    )}
                                    <p className="margin-none">----------------</p>
                                    { ((vehicleFullCheck.HighRiskRecordList !== null && vehicleFullCheck.Exported === null) || vehicleFullCheck.Exported === false) ? (
                                        <strong>This car has not been exported</strong>
                                    ) : (<div>
                                            <strong>This car HAS been exported</strong>
                                            <p>Export Date: {vehicleFullCheck.ExportDate} </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderStolenRecordResult = () => {
        let ifPass = vehicleFullCheck.StolenMiaftrRecordResult === 'Pass';
        const textColor = ifPass ? 'pass-green' : 'fail-red';
        const text =  ifPass ? 'Pass' : 'Fail';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className={`vehicle-info-left bottom-border ${textColor}`}>
                                    Stolen [{text}]
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="vehicle-info-left card-font-small">
                                    {ifPass ?
                                        (<b>This car is not reported as stolen</b>) :
                                        (<div>
                                            <h5>Car IS reported as stolen</h5>
                                            <p>Reporting Police Force: {vehicleFullCheck.StolenPoliceForce} </p>
                                            <p>Contact number: {vehicleFullCheck.StolenContactNumber} </p>
                                            <p>Date Stolen: {vehicleFullCheck.StolenDate} </p>
                                            <p>Source of Stolen Information: {vehicleFullCheck.StolenInfoSource} </p>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderMileageAnomalyResult = () => {
        const ifPass = vehicleFullCheck.MileageAnomalyResult === 'Pass';
        const textColor = ifPass ? 'pass-green' : 'fail-red';

        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        {renderPassIcon(ifPass)}
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className={`vehicle-info-left bottom-border ${textColor}`}>
                                    Mileage [{vehicleFullCheck.MileageAnomalyResult}]
                                </h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="vehicle-info-left card-font-small">
                                    { ((vehicleFullCheck.HighRiskRecordList !== null && vehicleFullCheck.MileageAnomalyDetected === null) || vehicleFullCheck.MileageAnomalyDetected === false) ?
                                        (<b>Mileage anomaly not detected</b>):
                                        (<b>Mileage anomaly detected</b>)
                                    }
                                    {vehicleFullCheck.MileageRecordList.length > 0 && (
                                        <div>
                                            <p className="margin-left">----------------</p>
                                            {vehicleFullCheck.MileageRecordList.map((m, i) => (
                                                <div key={`MileageRecordList_${i}`}>
                                                    <p>Mileage: {m.Mileage} </p>
                                                    <p>Mileage date: {m.DateOfInformation} </p>
                                                    <p>Mileage source: {m.SourceOfInformation}</p>
                                                    <p className="margin-left">----------------</p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderVehicleHistory = () => {
        return(
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        <div className="car-logo-report"></div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left bottom-border">Vehicle History</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="vehicle-info-left card-font-small">
                                    <p>Number of times the car has changed colour: {vehicleFullCheck.ColourChangeCount} </p>
                                    <p>Number of Previous Keepers: {vehicleFullCheck.PreviousKeeperCount} </p>
                                    <p>Last Change of Keeper: {vehicleFullCheck.LatestKeeperChangeDate} </p>
                                    <p>Number of plate changes: {vehicleFullCheck.PlateChangeCount}</p>
                                    {vehicleFullCheck.PlateChangeList && vehicleFullCheck.PlateChangeList.length ? (
                                        <div>
                                            <p>Plate Changes: </p>
                                            <p className="margin-left ">----------------</p>
                                            {vehicleFullCheck.PlateChangeList.map((p, i) => (
                                                <div key={`PlateChangeList_${i}`}>
                                                    <p className="margin-left">{p.PreviousVrm}</p>
                                                    <p className="margin-left">{p.DateChanged}</p>
                                                    <p className="margin-left">----------------</p>
                                                </div>
                                            ))}
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderCarCheck = () => {
        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        <div className="car-logo-report">

                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left bottom-border">Car Check</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left card-font-small">
                                    <p>If you view the car, you should check that the information in this section matches the car and the V5c. If it doesn't, you shouldn't buy the car.</p>
                                    <p>Last 5 digits of the VIN: {vehicleFullCheck.VinLast5}</p>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderCarReference = () => {
        return (
            <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                    <div className="col-sm-2">
                        <div className="car-logo-report"></div>
                    </div>
                    <div className="col-sm">
                        <div className="row">
                            <div className="col-sm">
                                <h3 className="vehicle-info-left bottom-border">Vehicle Reference</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <h5 className="vehicle-info-left card-font-small">
                                    <p>Date first registered: {vehicleFullCheck.DateFirstRegisteredUk} </p>
                                    <p>Year of manufacture: {vehicleFullCheck.YearOfManufacture} </p>
                                    <p>Colour: {vehicleFullCheck.Colour} </p>
                                    <p>Fuel type: {vehicleFullCheck.FuelType} </p>
                                    <p>Engine capacity: {vehicleFullCheck.EngineCapacity} </p>
                                    <p>Transmission: {vehicleFullCheck.TransmissionType} </p>
                                    <p>Number of gears: {vehicleFullCheck.GearCount} </p>
                                </h5>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    const renderReport = () => {
        return (
            <div>
                <h2 className="top-gap">Your Report </h2>
                {renderOverallResult()}
                {renderFinanceRecordResult()}
                {renderWriteOffRecordCount()}
                {renderScrapResult()}
                {renderImportExportResult()}
                {renderStolenRecordResult()}
                {renderMileageAnomalyResult()}
                {renderVehicleHistory()}
                {renderCarCheck()}
                {renderCarReference()}
            </div>
        );
    }

    return (
        <div>
            { loading ? renderLoading() : ''}
            <div className="container-score">
                {user && (
                    <div className="scorebg">
                        {renderUserScoreInfo()}
                        {(user.checks <= 0) && (
                            <Link to="/checkout">
                                <a>Click here to buy more credits</a>
                            </Link>
                        )}
                        {renderRegNumberForm()}
                        {vehicleFullCheck ? renderReport() : ''}
                    </div>
                )}
            </div>
        </div>
    )
};

export default Vdi;
