import axios from "axios";

export const getUser = async () => {
    let email = window.localStorage.getItem('emailForRegistration');

    if (email !== null && email !== '') {
        return await axios.get(`${process.env.REACT_APP_API}api/user/${email}`)
    }
    return null;
}

export const getCar = async (car) =>
    await axios.get(`${process.env.REACT_APP_API}api/vehicle/score/${car}`)
