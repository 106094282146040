import React, {  useState, useEffect } from 'react';
import '../../App.css';
import { auth } from '../../firebase';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';


const ForgotPassword = ({ history }) => {
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => ({ ...state }));


    useEffect((history) => {
        if (user && user.token) history.push('/');
    }, [user]);


        //BACKEND EXPOSURE POINT!
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const config = {
              url: `${process.env.REACT_APP}#/login/`,
            handleCodeInApp: true
        };

        await auth.sendPasswordResetEmail(email, config)
            .then(() => {
                setEmail('')
                setLoading(false)
                toast.success(<div><div className="car-contain"><div className="car-img-success" /></div><div className="car-img-text">Password reset link has been sent to your email. Be sure to check your junk mailbox too!</div></div>,
          
                {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
                });
            })
            .catch((error) => {
                setLoading(false);
                toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">{error.message}</div></div>,
          
                {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
                });
        })
        
    }

    return (
            <div className="logincontain">
                <div class="page-header">
                    {loading ? 
                    <h1 className="textDanger">Loading</h1>
                    : 
                    <h1>Forgotten Password</h1>
                    }
                </div>
                <form onSubmit={handleSubmit}>
                <div className="form-group grey-input">
                    <label>Email*</label>
                    <input type="danger"
                        name='email'
                        placeholder="Your Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                    className="form-control"
                    />
                </div>
                <input  type="submit" value="Submit" className="btn btn-info" disabled={!email}
                    />
                </form>
            </div>
    );
}

export default ForgotPassword;
