import React, {useState, useEffect} from 'react';
import '../App.css';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios';
import Fuse from 'fuse.js';
import {getUser} from '../functions/user';
import ReactLoading from 'react-loading';
import {toast as toastmain} from 'react-toastify';
import A from '../img/Small_Poor.svg';
import B from '../img/Small_WAvg.svg';
import Big2 from '../img/Large_WAvg.svg';
import C from '../img/Small_Avg.svg';
import D from '../img/Small_Good.svg';
import E from '../img/Small_Pristine.svg';
import one from '../img/Sort_Up.svg';
import two from '../img/Sort_Down.svg';
import four from '../img/Non_Filter.svg';
import {useMediaQuery} from 'react-responsive';
import {toast as toasty} from 'react-hot-toast';

let filt = four;
let rfilt = four;
let mfilt = four;

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

const Shortlist = ({history}) => {
    const isShortListTooltip = JSON.parse(window.localStorage.getItem('short-list-tooltip'));
    const [shortlistTooltipAccepted, setShortlistTooltipAccepted] = useState(isShortListTooltip);
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1000px)'})
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1000px)'
    })
    let dispatch = useDispatch();
    const forceUpdate = useForceUpdate();

    const [cars, setCars] = useState([]);
    const [state, setState] = useState("");
    const [price, setPrice] = useState("");
    const [url, setUrl] = useState("");
    const [id, setId] = useState("");
    const [sortp, setSortp] = useState("");
    const [sorts, setSorts] = useState("");
    const [sortm, setSortm] = useState("");
    const [odata, setOdata] = useState([]);
    const [text, setText] = useState("");
    const [score, setScore] = useState();
    const [value, setValue] = useState(0); // integer state

    const [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffcc00");
    let [type, setType] = useState("bars");

    const {user} = useSelector((state) => ({...state}));


    useEffect(() => {

        try {
            axios.get(`${process.env.REACT_APP_API}api/vehicle/admin`)
                .then(body => (setScore(body.data)));

            setValue(value => ++value);


        } catch (err) {

        }

        getUser()
            .then((res) => {
                setCars(res.data.short);

                if (res.data.short.length === 0) {
                    toastMe();
                }

                setOdata(res.data.short);
                setLoading(false);

            })
            .catch((err) => {
                setLoading(false);
            });

        dispatch({
            type: 'LOGGED_HPI',
            payload: null
        });


    }, []);

    for (let a = 0; a < cars.length; a++) {
        var info;
        var astar;
        if (cars[a].vehicleAvgInfo <= 0) {
            info = E;
            astar = 5;
        } else if (cars[a].vehicleAvgInfo < 79) {
            info = D;
            astar = 4;
        } else if (cars[a].vehicleAvgInfo < 120) {
            info = C;
            astar = 3;
        } else if (cars[a].vehicleAvgInfo < 199) {
            info = Big2;
            astar = 2;
        } else {
            info = A;
            astar = 1;
        }

        var fail;
        var fstar;
        if (cars[a].vehicleAvgFail <= 0) {
            fail = E;
            fstar = 5;
        } else if (cars[a].vehicleAvgFail < 79) {
            fail = D;
            fstar = 4;
        } else if (cars[a].vehicleAvgFail < 120) {
            fail = C;
            fstar = 3;
        } else if (cars[a].vehicleAvgFail < 199) {
            fail = Big2;
            fstar = 2;
        } else {
            fail = A;
            fstar = 1;
        }

        var overall = (astar + fstar) / 2;
        if (overall === 5) {
            cars[a].over = E;
            cars[a].overallNew = overall;
        } else if (overall >= 4) {
            cars[a].over = D;
            cars[a].overallNew = overall;
        } else if (overall >= 3) {
            cars[a].over = C;
            cars[a].overallNew = overall;
        } else if (overall >= 2) {
            cars[a].over = B;
            cars[a].overallNew = overall;
        } else {
            cars[a].over = A;
            cars[a].overallNew = overall;
        }
    }

    async function onSub(shortid) {
        setLoading(true);
        const email = user.email;
        const body = JSON.stringify({shortid, email});

        try {
            axios.delete(`${process.env.REACT_APP_API}api/shortlist/remove`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: body
            })


        } catch (error) {

        }
        window.location.reload();
    }


    async function editt(id) {
        forceUpdate();


        for (let i = 0; i < cars.length; i++) {
            if (cars[i]._id == id) {
                cars[i].edit = true;

                let collection = cars;
                collection[i].edit = true;
                setCars(collection);

                setState(".");
            }
        }
    }


    const onSubmit = async e => {
        setLoading(true);
        const email = user.email;
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({id, price, url, email});


        try {
            await axios.post(`${process.env.REACT_APP_API}api/shortlist/update`, body, config);


        } catch (err) {
            toastmain.error(<div>
                    <div className="car-contain">
                        <div className="car-img-error"/>
                    </div>
                    <div className="car-img-text">Edit not saved. Make sure you only enter numbers in
                        the price field
                    </div>
                </div>,

                {
                    position: "top-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });

        }

        setLoading(false);
    }

    function viewMore(reg) {
        toasty.dismiss();
        setLoading(true);
        window.localStorage.setItem('score', reg);

        history.push('/score/' + reg);

    }

    const fuse = new Fuse(cars, {
        keys: ['vehicleMake',
            'vehicleModel'],

        threshold: 0
    })


    const search = async e => {
        setCars(odata);
        const fer = await fuse.search(e.target.value);

        const arr = [];


        setText(...text + e.target.value);

        try {


            for (var x = 0; x < fer.length; x++) {

                arr[x] = fer[x].item;
            }

            setCars(arr);

            if (arr.length === 0) {
                toastMe();
            }

        } catch (err) {

        }
        if (e.target.value === "") {
            setCars(odata);
        }
        forceUpdate();
    }

    function toastMe() {
    }

    function pricesort() {
        if (sortp == "") {
            setSortp("up");

            filt = two;


            const breweries = [...cars].sort((a, b) => {
                if (a.price < b.price) return -1;
                if (a.price > b.price) return 1;
                return 0;
            });
            setCars(breweries);
            setState(".");

        } else if (sortp == "up") {
            setSortp("down");

            filt = one;

            const breweries = [...cars].sort((a, b) => {
                if (a.price > b.price) return -1;
                if (a.price < b.price) return 1;
                return 0;
            });
            setCars(breweries);
            setState(".");

        } else if (sortp == "down") {
            setSortp("");

            filt = four;
            setCars(odata);
            setState(".");
        }
    }

    function ratingsort() {
        if (sorts == "") {
            setSorts("up");

            rfilt = two;
            const brew = [...cars].sort((a, b) => {
                if (a.overallNew < b.overallNew) return -1;
                if (a.overallNew > b.overallNew) return 1;
                return 0;
            });
            setCars(brew);
            setState(".");

        } else if (sorts == "up") {
            setSorts("down");

            rfilt = one;
            const brew = [...cars].sort((a, b) => {
                if (a.overallNew > b.overallNew) return -1;
                if (a.overallNew < b.overallNew) return 1;
                return 0;
            });
            setCars(brew);
            setState(".");


        } else if (sorts == "down") {
            setSorts("");

            rfilt = four;
            setCars(odata);
            setState(".");
        }

    }

    function makesort() {
        if (sortm == "") {
            setSortm("up");

            mfilt = two;
            const brew = [...cars].sort((a, b) => {
                if (a.vehicleMake < b.vehicleMake) return -1;
                if (a.vehicleMake > b.vehicleMake) return 1;
                return 0;
            });
            setCars(brew);
            setState(".");

        } else if (sortm == "up") {
            setSortm("down");

            mfilt = one;
            const brew = [...cars].sort((a, b) => {
                if (a.vehicleMake > b.vehicleMake) return -1;
                if (a.vehicleMake < b.vehicleMake) return 1;
                return 0;
            });
            setCars(brew);
            setState(".");


        } else if (sortm == "down") {
            setSortm("");

            mfilt = four;

            setCars(odata);
            setState(".");
        }

    }

    async function hpiRedirect(reg) {
        try {

            const email = user.email;

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const vehicleRegistration = reg;

            const body = ({vehicleRegistration});

            var result = await axios.post(`${process.env.REACT_APP_API}api/vdi/user/` + email, body, config);
            if (result.data === "") {
                dispatch({
                    type: 'LOGGED_HPI',
                    payload: {
                        vehicleRegistration
                    }
                })
            } else {

            }
        } catch (err) {

        }

        history.push('/vdi-check');
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function confirmYes(_id) {
        onSub(_id);
        toasty.dismiss();
    }

    function confirmNo() {

        toasty.dismiss();
    }

    function toastPop(_id) {
        toasty(<>
                <div className="car-logo-confirm-container">
                    <div className="car-logo-confirm"/>
                </div>
                <div>
                    <div className="car-img-text">Are you sure you want to delete this car?
                        <div className="btns">
                            <div className="btn-yes" type="button"
                                 onClick={() => (confirmYes(_id))}>Yes
                            </div>
                            <div className="btn-no" type="button" onClick={() => (confirmNo())}>No</div>
                        </div>
                    </div>
                </div>
            </>,
            {duration: 500000});

    }

    const closeTooltip = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShortlistTooltipAccepted(true);
        window.localStorage.setItem('short-list-tooltip', 'true');
    }

    const renderTooltip = () => {
        return !shortlistTooltipAccepted && (
            <div className="short-list-tooltip">
                You can sort cars in your shortlist using the buttons and add the price and a link
                to the ad by clicking on Edit. Click
                <a href="https://carxpert.co.uk/your-shortlist/" target="_blank">here</a>
                to find out more
                <a href="/" onClick={closeTooltip} className="short-list-tooltip-close">х</a>
            </div>
        )
    }

    return (
        <>

            {loading ? (
                <ReactLoading className="loading-screen" type={type} color={color} height={667}
                              width={375}/>) : (<></>)}
            <div className="container">
                {renderTooltip()}
                {(isTabletOrMobileDevice === false && isTabletOrMobile === false) && (
                    <>
                        <div className="scorebg">
                            <div class="page-header">
                                <h1 className="regsignuph">Your Shortlist</h1>


                            </div>
                            <div className="topp">
                                <form onSubmit={e => search(e)} className="form-group grey-input">
                                    <input type="text"
                                           name='url'
                                           autocomplete="off"
                                           className="form-control"
                                           onChange={(e) => search(e)}
                                           placeholder="Search by make, model"
                                    />
                                </form>
                            </div>
                            <div className='row'>
                                <div className="col-xs filter-seg" onClick={() =>
                                    ratingsort()}>
                                    <div className="filter-btn">

                                        Rating
                                        <div className="colar">
                                            <img src={rfilt} alt="Rating"
                                                // onMouseOver={e => (e.currentTarget.src = HoverMe)}
                                                //         onMouseOut={e => (e.currentTarget.src = c.over)}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xs filter-seg">
                                    <div className="filter-btn" onClick={() => makesort()}>
                                        Make
                                        <div className="colar">
                                            <img src={filt} alt="Rating"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs filter-seg">
                                    <div className="filter-btn" onClick={() => pricesort()}>
                                        Price
                                        <div className="colar">
                                            <img src={filt} alt="Rating"/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {
                                cars[0] ? (cars.map((c) => (
                                    c.edit === true ? (
                                        <>
                                            <form onSubmit={e => onSubmit(e)}>
                                                <div className="cards">
                                                    <div className="row row-card-desktop">

                                                        <div className="col-sm-8">
                                                            <div className="row margin-bottom">
                                                                <div className="col-sm-6 ">
                                                                    <p className="cards-text-bold">{c.vehicleMake} {c.vehicleModel}</p>
                                                                </div>
                                                                <div className="col-sm ">
                                                                    <p className="cards-text">
                                                                        <div className="price-tag"/>
                                                                        £{numberWithCommas(c.price)}
                                                                    </p>
                                                                </div>
                                                                <div className="col-sm ">
                                                                    <p className="cards-text">
                                                                        <div
                                                                            className="mileage-tag"/>
                                                                        {c.vehicleMileage}</p>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-6 1">
                                                                    <div className="cards-text"><img
                                                                        src={c.over} alt="score"
                                                                        width="80%"
                                                                    /></div>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div className="card-reg">
                                                                        <p className="cards-text-reg">{c.vehicleRegistration.toUpperCase()}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-sm">
                                                            <div className="row">
                                                                <div className="col-sm ">

                                                                </div>
                                                                <div className="col-sm ">
                                                                    <div className="delete-button"
                                                                         type="button"
                                                                         onClick={() => toastPop(c._id)}>
                                                                        <div className="bin-icon"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm ">
                                                                    <a target='_blank'
                                                                       href={"https://" + c.url}>
                                                                        <div
                                                                            className="ad-container">
                                                                            <div
                                                                                className="int-logo"/>


                                                                            <div
                                                                                className="int-link">

                                                                                <p className="cards-text capped">{c.url.toUpperCase()}</p>

                                                                            </div>

                                                                        </div>
                                                                    </a>
                                                                </div>


                                                            </div>
                                                        </div>


                                                        <div className="card-bottomm">
                                                            <div className="row transp">
                                                                <div className="col-xs-XX halff">
                                                                    <div className="row transp">
                                                                        <div className="col-xs">
                                                                            <p className="overpass-font">Price: </p>
                                                                            <div className="inline">
                                                                                <input type="number"
                                                                                       maxLength="10"
                                                                                       name='price'
                                                                                       placeholder={c.price}
                                                                                       value={price}
                                                                                       onChange={(e) => setPrice(e.target.value)}
                                                                                       className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row transp">
                                                                        <div className="col-xs">
                                                                            <p className="overpass-font">Advert
                                                                                Link: </p>
                                                                            <div className="inline">
                                                                                <input type="text"
                                                                                       name='url'
                                                                                       value={url}
                                                                                       placeholder={c.url}
                                                                                       onChange={(e) => setUrl(e.target.value)}
                                                                                       className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-XX half">

                                                                    <input
                                                                        onClick={() => setId(c._id)}
                                                                        type="submit" value="Save"
                                                                        className="btn shortlist-save-btn"
                                                                    />
                                                                    <input
                                                                        onClick={() => c.edit = false}
                                                                        type="submit" value="Cancel"
                                                                        className="btn shortlist-cancel-btn"
                                                                    />


                                                                </div>

                                                            </div>

                                                        </div>


                                                    </div>
                                                </div>
                                            </form>
                                        </>) : (<>

                                        <div className="cards">
                                            <div className="row row-card-desktop">

                                                <div className="col-sm-8">
                                                    <div className="row margin-bottom">
                                                        <div className="col-sm-6 ">
                                                            <p className="cards-text-bold">{c.vehicleMake} {c.vehicleModel}</p>
                                                        </div>
                                                        <div className="col-sm ">
                                                            <p className="cards-text">
                                                                <div className="price-tag"></div>
                                                                £{numberWithCommas(c.price)} </p>
                                                        </div>
                                                        <div className="col-sm ">
                                                            <p className="cards-text">
                                                                <div className="mileage-tag"></div>
                                                                {c.vehicleMileage}</p>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-sm-6 1">
                                                            <div className="cards-text"><img
                                                                src={c.over} alt="score" width="80%"
                                                            /></div>
                                                        </div>
                                                        <div className="col-sm">
                                                            <div className="card-reg">
                                                                <p className="cards-text-reg">{c.vehicleRegistration.toUpperCase()}</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="row">
                                                        <div className="col-sm ">
                                                            <div className="edit-button"
                                                                 type="button"
                                                                 onClick={() => editt(c._id)}>Edit
                                                            </div>

                                                            <div className="delete-button"
                                                                 type="button"
                                                                 onClick={() => toastPop(c._id)}>
                                                                <div className="bin-icon"/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm ">
                                                            <a target='_blank'
                                                               href={"https://" + c.url}>
                                                                <div className="ad-container">
                                                                    <div className="int-logo"/>


                                                                    <div className="int-link">

                                                                        <p className="cards-text capped">{c.url.toUpperCase()}</p>

                                                                    </div>

                                                                </div>
                                                            </a>
                                                        </div>


                                                    </div>
                                                </div>


                                                <div className="card-bottom">
                                                    <div className="row transp">
                                                        <div className="col-xs-XX halff">

                                                        </div>
                                                        <div className="col-xs-XX half">

                                                            <p className="cards-texts" type="button"
                                                               onClick={() => viewMore(c.vehicleRegistration)}>View
                                                                More <div
                                                                    className="left-arrow"></div>
                                                            </p>


                                                        </div>

                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </>))
                                )) : (
                                    <h2>No matching vehicles found </h2>
                                )
                            }


                        </div>
                    </>
                )}

                {(isTabletOrMobileDevice || isTabletOrMobile) && (
                    <>
                        <div className="scorebg">
                            <div class="page-header">
                                <h1 className="regsignuph">Your Shortlist</h1>
                            </div>
                            <div className="topp">
                                <form onSubmit={e => search(e)} className="form-group grey-input">
                                    <input type="text"
                                           name='url'
                                           autocomplete="off"
                                           className="form-control"
                                           onChange={(e) => search(e)}
                                           placeholder="Search By Registration, Make ..."
                                    />
                                </form>
                            </div>
                            <div className='row'>
                                <div className="col-xs filter-seg" onClick={() =>
                                    ratingsort()}>
                                    <div className="filter-btn">

                                        Rating
                                        <div className="colar">
                                            <img src={rfilt} alt="Rating"/>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xs filter-seg">
                                    <div className="filter-btn" onClick={() => makesort()}>
                                        Make
                                        <div className="colar">
                                            <img src={filt} alt="Rating"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs filter-seg">
                                    <div className="filter-btn" onClick={() => pricesort()}>
                                        Price
                                        <div className="colar">
                                            <img src={filt} alt="Rating"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                cars[0] ? (cars.map((c) => (
                                    c.edit === true ? (
                                        <>
                                            <form onSubmit={e =>
                                                onSubmit(e)}>
                                                <div className="cards">
                                                    <div className="row row-card">
                                                        <div className="col-xs-XX halff">
                                                            <div className="col-xs">
                                                                <p className="cards-text-bold">{c.vehicleMake} {c.vehicleModel}</p>
                                                            </div>
                                                            <div className="col-xs card-boxx">
                                                                <p className="cards-text">
                                                                    <div
                                                                        className="price-tag"></div>
                                                                    £{numberWithCommas(c.price)}
                                                                    <div
                                                                        className="mileage-tag"></div>
                                                                    {c.vehicleMileage}</p>
                                                            </div>
                                                            <div className="col-xs">
                                                                <p className="cards-text">{c.vehicleRegistration.toUpperCase()}</p>
                                                            </div>
                                                            <div className="col-xs">
                                                                <div className="cards-text"><img
                                                                    src={c.over} alt="score"
                                                                    width="80%"
                                                                /></div>
                                                            </div>


                                                        </div>

                                                        <div className="col-xs-XX half">
                                                            <div className="col-xs card-buttons">
                                                                <div className="delete-button"
                                                                     type="button"
                                                                     onClick={() => toastPop(c._id)}>
                                                                    <div className="bin-icon"/>
                                                                </div>
                                                                <input onClick={() => setId(c._id)}
                                                                       type="submit" value="Save"
                                                                       className=""
                                                                />
                                                                <input
                                                                    onClick={() => c.edit = false}
                                                                    type="submit" value="Cancel"
                                                                    className=""
                                                                />
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="card-bottomm">
                                                        <div className="row transp">
                                                            <div className="col-sm padding-top">
                                                                <div className="row transp">
                                                                    <div className="col-xs">
                                                                        <p className="overpass-font">Price: </p>
                                                                        <div
                                                                            className="inline-mobile">
                                                                            <input type="number"
                                                                                   maxLength="10"
                                                                                   name='price'
                                                                                   placeholder={c.price}
                                                                                   value={price}
                                                                                   onChange={(e) => setPrice(e.target.value)}
                                                                                   className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row transp">
                                                                    <div className="col-xs">
                                                                        <p className="overpass-font">Advert
                                                                            Link: </p>
                                                                        <div
                                                                            className="inline-mobile">
                                                                            <input type="text"
                                                                                   name='url'
                                                                                   value={url}
                                                                                   placeholder={c.url}
                                                                                   onChange={(e) => setUrl(e.target.value)}
                                                                                   className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </>) : (<>

                                        <div className="cards">
                                            <div className="row row-card">
                                                <div className="col-xs-XX halff">
                                                    <div className="col-xs">
                                                        <p className="cards-text-bold">{c.vehicleMake} {c.vehicleModel}</p>
                                                    </div>
                                                    <div className="col-xs">
                                                        <p className="cards-text">
                                                            <div className="price-tag"></div>
                                                            £{numberWithCommas(c.price)}
                                                            <div className="mileage-tag"></div>
                                                            {c.vehicleMileage}</p>
                                                    </div>
                                                    <div className="col-xs">
                                                        <div className="card-reg">
                                                            <p className="cards-text-reg">{c.vehicleRegistration.toUpperCase()}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs">
                                                        <div className="cards-text"><img
                                                            src={c.over} alt="score" width="80%"
                                                        /></div>
                                                    </div>


                                                </div>

                                                <div className="col-xs-XX half">
                                                    <div className="col-xs">
                                                        <div className="delete-button" type="button"
                                                             onClick={() => toastPop(c._id)}>
                                                            <div className="bin-icon"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-xs">
                                                        <div className="edit-button" type="button"
                                                             onClick={() => editt(c._id)}>Edit
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="card-bottom">
                                                <div className="row transp">
                                                    <div className="col-xs-XX halff">
                                                        <a target='_blank'
                                                           href={"https://" + c.url}>
                                                            <p className="cards-text capped">{c.url}</p>
                                                        </a>
                                                    </div>
                                                    <div className="col-xs-XX half">

                                                        <p className="cards-texts"
                                                           onClick={() => viewMore(c.vehicleRegistration)}>View
                                                            More <div className="left-arrow"></div>
                                                        </p>


                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </>))
                                )) : (
                                    <h2>No matching vehicles found </h2>
                                )
                            }


                        </div>
                    </>
                )}
            </div>
        </>
    );
};


export default Shortlist;

