import React, {  useState, useEffect } from 'react';
import { useSelector} from 'react-redux';
import '../../App.css';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckout from 'react-stripe-checkout';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import Car from '../../img/carxpertcar.png';
import { getCar } from '../../functions/user';
import { useMediaQuery } from 'react-responsive';
import {toast as toasty, Toaster} from 'react-hot-toast';





const Full = ({ history }) => {


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 600px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 600px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  const stripePromise = loadStripe('pk_test_51IGMioEIJjMRbuQ3C4yOU9Tz0pXnnrU3HGcD8tYewALqn1IpZRGI2W9HvjbYI72TxGRVDJpsZiuxbULTbNnr7jl100dyQXXm5X');
    const dispatch = useDispatch();
    const [reg, setReg] = useState("");
    const [state, setState] = useState("");
    const [vehicleRegistration, setVehicleRegistraion] = useState("");
    const [vehicleId, setVehicleId] = useState("");
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [vehicleColour, setVehicleColour] = useState("");
    const [vehicleEngine, setVehicleEngine] = useState("");
    const [vehicleAvgInfo, setVehicleAvgInfo] = useState("");
    const [vehicleAvgFail, setVehicleAvgFail] = useState("");
    const [vehicleLastMot, setVehicleLastMot] = useState("");
    const [vehicleFuel, setVehicleFuel] = useState("");
    const [vehicleMileage, setVehicleMileage] = useState("");
    const [vehicleYear, setVehicleYear] = useState("");
   const [save, setSave] = useState("");
    const [car, setCar] = useState([]);
    const [checker, setChecker] = useState("");
  const [credited, setCredited] = useState("");
  const [prod, setProd] = useState({});
  const [prodOne, setProdOne] = useState("false one");
  const [prodTwo, setProdTwo] = useState("false two");
  const [prodThree, setProdThree] = useState("false three");
  const [css_start_one, setCss_start_one] = useState("btn onecheck");
  const [css_start_two, setCss_start_two] = useState("btn threecheck");
  const [css_start_three, setCss_start_three] = useState("btn fivecheck");



  //Stripe products
  const [product, setProduct] = useState({name: "One History Check",
  price: 1.00,
  currency: 'GBP',
    qty: 1});
  const [productOn, setProductOn] = useState({name: "Three History Checks",
  price: 2.00,
  currency: 'GBP',
    qty: 3});
    const [productTw, setProductTw] = useState({ name: "Five History Checks",
    price: 3.00,
    currency: 'GBP',
      qty: 5});


   const [css, setCss] = useState("");
   const [score, setScore] = useState({})

   const { user, vehicle } = useSelector((state) => ({ ...state }));



    useEffect(() => {

       setCss("colourrrr");

       getCar(window.localStorage.getItem('score'))
       .then((res) => {
         setVehicleRegistraion(res.data.vehicleRegistration);
         setVehicleId(res.data._id);
         setVehicleMake(res.data.vehicleMake);
         setVehicleModel(res.data.vehicleModel);
         setVehicleColour(res.data.vehicleColour);
         setVehicleEngine(res.data.vehicleEngine);
         setVehicleAvgInfo(res.data.vehicleAvgInfo);
         setVehicleAvgFail(res.data.vehicleAvgFail);
         setVehicleLastMot(res.data.vehicleLastMot);
         setVehicleMileage(res.data.vehicleMileage);
         setVehicleYear(res.data.vehicleYear);
        setVehicleFuel(res.data.vehicleFuel);
          setCar(res.data.motTests);

       })
          .catch((err) => {
            toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">{err.message}</div></div>,

            {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
            });
             history.push('/');
          });

       try {
          var body = axios.get(`${process.env.REACT_APP_API}api/vehicle/admin`)
             .then(body => (setScore(body.data)));
       }
       catch(err) {

       }
  }, [user]);


  //Functions to deal with 3 products. Change to array of products!

  function produc(prod) {
    if (prod === "false one") {
      setCss_start_one("btn onecheck border");
      setProdOne("true one");
      setCss_start_two("btn threecheck");
      setCss_start_three("btn fivecheck");
      setProdTwo("false two");
      setProdThree("false three");
      setState(".");
    } else if (prod === "false two") {
      setProdTwo("true two");
      setCss_start_two("btn threecheck border");
      setCss_start_one("btn onecheck");
      setCss_start_three("btn fivecheck");
      setProdOne("false one");
      setProdThree("false three");
      setState(".");
    } else if (prod === "false three") {
      setProdThree("true three");
      setCss_start_three("btn fivecheck border");
      setCss_start_two("btn threecheck");
      setCss_start_one("btn onecheck");
      setProdTwo("false two");
      setState(".");
    }
  }

    async function credit(cred) {
        setChecker(cred);

        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const body = ({ checks: cred, email: user.email });

          try {
            const res = await axios.post(`${process.env.REACT_APP_API}api/user/update/`, body, config);


              var updat = await axios.get(`${process.env.REACT_APP_API}api/user/${user.email}`);

              dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                  email: user.email,
                  displayName: user.displayName,
                    phoneNumber: user.phoneNumber,
                    marketingAuth: user.marketingAuth,
                    checks: updat.data.checks
                  // firstName: body.data.firstName || user.email.split('@')[0],
                //   token: idTokenResult.token,
                }
              })


          } catch (error) {

          }


   }

  async function handleToken(token, addresses) {
    const response = await axios.post(`${process.env.REACT_APP_API}api/checkout`, { token, product });
        const { status } = response.data;
        if (status === 'success') {

          toast.success(<div><div className="car-contain"><div className="car-img-success" /></div><div className="car-img-text">Success! Check email for details</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });


          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const body = ({ checks: product.qty, email: user.email });

          try {
            const res = await axios.post(`${process.env.REACT_APP_API}api/user/update/`, body, config);


              var updat = await axios.get(`${process.env.REACT_APP_API}api/user/${user.email}`);

            dispatch({
              type: 'LOGGED_IN_USER',
              payload: {
                email: user.email,
                displayName: user.displayName,
                phoneNumber: user.phoneNumber,
                marketingAuth: user.marketingAuth,
                checks: updat.data.checks
              }
            }
            );
            dispatch({
              type: 'LOGGED_HPI',
              payload: {
                vehicleRegistration: window.localStorage.getItem('score') || "Enter"
              }
            });

              history.push('/vdi-check');
          } catch (error) {

          }
        } else if (status === 'failure') {


          toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Sorry, we can't process your payment. Please check the details of what you
          entered and try again. If that doesn't work please try another payment method</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });
        }
  }

  async function handleTokenOn(token, addresses) {
    const response = await axios.post(`${process.env.REACT_APP_API}api/checkout`, { token, productOn });
        const { status } = response.data;
        if (status === 'success') {

          toast.success(<div><div className="car-contain"><div className="car-img-success" /></div><div className="car-img-text">Thanks, you're now ready to get a history check. The
          credits number shows how many history checks you're able to request</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });

          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const body = ({ checks: productOn.qty, email: user.email });

          try {
            const res = await axios.post(`${process.env.REACT_APP_API}api/update/`, body, config);


              var updat = await axios.get(`${process.env.REACT_APP_API}api/user/${user.email}`);

              dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                  email: user.email,
                  displayName: user.displayName,
                    phoneNumber: user.phoneNumber,
                    marketingAuth: user.marketingAuth,
                    checks: updat.data.checks
                }
              })

            history.push('/vdi-check');
          }
          catch (error) {

          }
        } else if (status === 'failure') {
          toasty(<>
            <div className="car-logo-confirm-container"><div className="car-logo-confirm"/></div>
            <div><div className="car-img-text">Something went wrong
        <div className="btns"></div>
        </div></div></>,
            {duration: 6000});


        }
  }

  async function handleTokenTw(token, addresses) {
    const response = await axios.post(`${process.env.REACT_APP_API}api/checkout`, { token, productTw });
        const { status } = response.data;
        if (status === 'success') {

          toast.success(<div><div className="car-contain"><div className="car-img-success" /></div><div className="car-img-text">Success! Check email for details</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });

          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          }

          const body = ({ checks: productTw.qty, email: user.email });

          try {
            const res = await axios.post(`${process.env.REACT_APP_API}api/user/update/`, body, config);

              var updat = await axios.get(`${process.env.REACT_APP_API}api/user/${user.email}`);
              dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                  email: user.email,
                  displayName: user.displayName,
                    phoneNumber: user.phoneNumber,
                    marketingAuth: user.marketingAuth,
                    checks: updat.data.checks
                  // firstName: body.data.firstName || user.email.split('@')[0],
                //   token: idTokenResult.token,
                }
              })

              history.push('/vdi-check');
          }
          catch (error) {

          }

          // else if (status === 'failure') {

          //   toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Something went wrong</div></div>,

          //   {
          //   position: "top-center",
          //   autoClose: 5000,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined
          //   });
          // }


        } else{
          toasty(<>
            <div className="car-logo-confirm-container"><div className="car-img-error"/></div>
            <div><div className="car-img-text">Something went wrong with that payment.
        <div className="btns"></div>
        </div></div></>,
            { duration: 6000 });

        }
    }





        return(
            <div className="container-score">
            <div className="scorebg">
            <div className="row">
                  <div className="col-xs fullvehhead">
                     <h1 className="regsignuph">History Check</h1>
                  </div>
                  <div class="col-xs full-check-top">

                  </div>
               </div>
              <div className="row pad-top">
                <div className="col-sm">
                  <h2 className="top-gap text-left-check">What is a History Check? </h2>
                  <div className="text-left-check">
                    <p>MOT history is a great way of identifying which
                    cars have the best track record when it comes to
                    basic maintenance and roadworthiness, so it can help
                    you narrow down your search for your next car. What
                          the MOT history can’t tell you is   </p><br/>
                          <p>- Has the car been written off? <br></br>

                    - Has it been stolen? <br></br>

                        - Does it have outstanding Finance?  </p>
                    </div>
                </div>
                <div className="col-sm img-right">
                  <img src={Car} width="50%"/>
                </div>

                  </div>

                    <p>These are questions that a History Check can
                    answer, and we’d recommend you purchase one of
                    these for any vehicle you’re thinking of purchasing.
                    Also, if you’re travelling any distance to look at a
                    car we’d suggest you get a History Check beforehand as
                    it could save you some petrol money and time if the car
                    you were planning to look at has some skeletons in its closet.</p>
                    <br></br>


                    <h2 className="top-gap centre">Pricing</h2>
              <div className="row top-gap">


                <div className="col-sm">
                <StripeCheckout

                  stripeKey="pk_test_51IGMioEIJjMRbuQ3C4yOU9Tz0pXnnrU3HGcD8tYewALqn1IpZRGI2W9HvjbYI72TxGRVDJpsZiuxbULTbNnr7jl100dyQXXm5X"
                  currency="GBP"
                          token={handleToken}
                          billingAddress
                          amount={product.price*100}
                          name={product.name}
                      >
                    <div className={css_start_one} onClick={() => (produc(prodOne))}></div>
                    </StripeCheckout>
                </div>
                <div className="col-sm">
                <StripeCheckout

                  stripeKey="pk_test_51IGMioEIJjMRbuQ3C4yOU9Tz0pXnnrU3HGcD8tYewALqn1IpZRGI2W9HvjbYI72TxGRVDJpsZiuxbULTbNnr7jl100dyQXXm5X"
                  currency="GBP"
                          token={handleTokenOn}
                          billingAddress
                          amount={productOn.price*100}
                          name={productOn.name}
                      >
                   <div className={css_start_two} onClick={() => (produc(prodTwo))}></div>
                   </StripeCheckout>
                </div>
                <div className="col-sm">
                <StripeCheckout

                  stripeKey="pk_test_51IGMioEIJjMRbuQ3C4yOU9Tz0pXnnrU3HGcD8tYewALqn1IpZRGI2W9HvjbYI72TxGRVDJpsZiuxbULTbNnr7jl100dyQXXm5X"
                  currency="GBP"
                          token={handleTokenTw}
                          billingAddress
                          amount={productTw.price*100}
                          name={productTw.name}
                      >
                    <div className={css_start_three} onClick={() => (produc(prodThree))}></div>
                    </StripeCheckout>
                </div>
              </div>


                    <h2 className="top-gap">Example Report </h2>

                    <>

                  <h2 className="top-gap">Your Report </h2>







                {(isTabletOrMobileDevice === false && isTabletOrMobile === false) && (
                  <>


<div className="cards">
                <div className="row row-card-desktop desktop-padding">
                        <div className="col-sm-2">
                       <div className="cross-logo"/>



                  </div>
                  <div className="col-sm">

                    <div className="row">
                      <div className="col-sm">
                        <h3 className="vehicle-info-left bottom-border-mobile">Vehicle Report</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Make: Volkswagen</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Model: Polo</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Registration Number: JD51XYZ</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left"> Report Type: <div className="fail-red">FAIL</div>

</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Report issued on: 01/03/2021</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                        <div className="col-sm-2">
                       <div className="cross-logo"/>



                  </div>
                  <div className="col-sm">
                  <div className="row">
                            <div className="col-sm">
                     <h5 className="vehicle-info-left bottom-border fail-red">Finance [FAIL]</h5>

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small"> <p>This car has 1 agreement(s) outstanding</p>


                        <>
                                    <p>Finance List: </p>
                                    <div className="finance-info-left">
                      <p className="">----------------</p>

                            <>

                              <p className="">Start Date: 2017-10-06T00:00:00 </p>
                              <p className="">Agreement Number: 2044987 </p>
                              <p className="">Agreement Term(months): 40</p>
                              <p className="">Agreement Type: PCP </p>
                              <p className="">Agreement Contact: 0801 570111</p>
                              <p className="">Finance Company: NSU FINANCE SERVICES</p>
                              <p className="">Vehicle Desc: "Volkswagen Polo Diesel Blue"</p>
                              <p className="">----------------</p>
                            </>

                          </div>
                        </>


</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
                    <div className="cross-logo">

                    </div>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left bottom-border fail-red">Condition [FAIL]</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                              <h5 className="vehicle-info-left card-font-small">
                                <p>This car has 1 write-off markers</p>
                                <p>Date: 10/07/2020</p>


                        <>


                          <p>----------------</p>



                            <>

                              <p>MiaftrEntryDate: 10/07/2020</p>

                              <p>Write-off category: S</p>
                              <p>Type of Write-off: CAT S STRUCTURAL DAMAGE</p>

                                    <p>This car has not had a vehicle identifty check</p>

                              <p>----------------</p>
                            </>

                        </>




                              </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
              <div className="tick-logo"/>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                     <h5 className="vehicle-info-left bottom-border pass-green">Scrapped [PASS]</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                                <h5 className="vehicle-info-left card-font-small">
                      {/* <p>Scrapped: </p>
                      <p>ScrapDate:  </p>
                      <p>CertificateOfDestructionIssued:  </p> */}


                                  </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
                  <div className="cross-logo"/>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                    <h5 className="vehicle-info-left bottom-border fail-red">Import/Export [FAIL]</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small">

                      <p>Car has been imported</p>
                      <p>Date imported:  01/08/2017</p>


                    </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding desktop-padding">
                  <div className="col-sm-2">
                 <div className="tick-logo"/>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                     <h5 className="vehicle-info-left bottom-border pass-green">Stolen [PASS]</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small"><p>Car is not reported as stolen </p>
                      </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
                 <div className="tick-logo"/>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left bottom-border pass-green">Mileage [PASS]</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                              <h5 className="vehicle-info-left card-font-small">
                      <p>Mileage anomaly not detected</p>
                      <p>Mileage date:  01/03/2021</p>
                      <p>Mileage: 99,999 </p>
                      <p>Mileage source: MOT</p></h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
                    <div className="car-logo-report">

                    </div>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h3 className="vehicle-info-left bottom-border">Car History</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small">
                        <p> Number of Previous Keepers: 0 </p>
                        <p>Last Change of Keeper: 11/12/2019</p>
                                <p>Car has changed colour 0 times</p>
                        <p>Number of plate changes: 1</p>


                        <>
                          <p>Plate Changes: </p>
                          <p className="margin-left ">----------------</p>

                            <>



                            <p className="margin-left">Previous plate: BD68 SSS</p>
                              <p className="margin-left">Change date: 05/10/2018</p>

                              <p className="margin-left">----------------</p>
                            </>

                        </>
                      </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
                    <div className="car-logo-report">

                    </div>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h3 className="vehicle-info-left bottom-border">Car Check</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small">
                       <h6>If you view the car, you should check that the information in this section matches the car and the V5c. If it doesn't, you shouldn't buy the car.</h6>

                        <p>Last 5 digits of the VIN: 09506 </p>
                        <p>Latest V5c Issued Date:   11/12/2019</p>
                      </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">
                    <div className="car-logo-report">

                    </div>
                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h3 className="vehicle-info-left bottom-border">Car Reference</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small">


                      <p>Date first registered:  06/10/2017</p>
                      <p>Year manufactured:  2017</p>
                      <p>Colour:  Blue</p>
                      <p>Fuel type: Diesel</p>
                      <p>Engine capacity: 1600cc</p>
                      <p>Transmission:  Manual</p>
                      <p>Number of gears:  6</p>
                    </h5>
                      </div>
                    </div>

                  </div>
                </div>
                    </div>
                    </>
            )}

                {(isTabletOrMobileDevice === true || isTabletOrMobile === true) && (
                  <>
                  <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                <div className="col-sm">
                              <h5 className="vehicle-info-left bottom-border-mobile">Car Report</h5>
                              <div className="cross-logo-mobile"/>

                </div>

                  <div className="col-sm">


                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Make: Volkswagen</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Model: Polo</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Registration Number: JD51XYZ</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left"> Report Type: <div className="fail-red">FAIL</div>

</h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left">Report issued on: 01/03/2021</h5>
                      </div>
                    </div>
                  </div>
                </div>
                    </div>

                  <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                        <div className="col-sm-2">




                  </div>
                  <div className="col-sm">
                  <div className="row">
                            <div className="col-sm">
                             <h5 className="vehicle-info-left bottom-border-mobile fail-red">Finance [PASS]</h5>


                         <div className="cross-logo-mobile"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left card-font-small"> <p>This car has 1 agreement(s) outstanding</p>


                     <>
                                 <p>Finance List: </p>
                                 <div className="finance-info-left">
                   <p className="">----------------</p>

                         <>

                           <p className="">Start Date: 2017-10-06T00:00:00 </p>
                           <p className="">Agreement Number: 2044987 </p>
                           <p className="">Agreement Term(months): 40</p>
                           <p className="">Agreement Type: PCP </p>
                           <p className="">Agreement Contact: 0801 570111</p>
                           <p className="">Finance Company: NSU FINANCE SERVICES</p>
                           <p className="">----------------</p>
                         </>

                       </div>
                     </>


</h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                    <h5 className="vehicle-info-left bottom-border-mobile fail-red">Condition [FAIL]</h5>


                          <div className="cross-logo-mobile"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                              <h5 className="vehicle-info-left card-font-small">
                              <p>This car has 1 write-off markers</p>
                                <p>Date: 10/07/2020</p>


                        <>

                          <p>Write-off List: </p>
                          <p className="margin-left">----------------</p>



                            <>

                              <p className="margin-left">MiaftrEntryDate: 10/07/2020</p>


                              <p className="margin-left">Type of Write-off: CAT S STRUCTURAL DAMAGE</p>
                                    <p className="margin-left">Write-off category: S</p>
                                    <p className="margin-left">This car has not had a vvehicle identifty check</p>

                              <p className="margin-left">----------------</p>
                            </>

                        </>




                              </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                    <h5 className="vehicle-info-left bottom-border-mobile pass-green">Scrapped [PASS]</h5>

<div className="tick-logo-mobile"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                                <h5 className="vehicle-info-left card-font-small">
                      {/* <p>Scrapped: </p>
                      <p>ScrapDate: </p>
                      <p>CertificateOfDestructionIssued: </p> */}


                                  </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>



              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                     <h5 className="vehicle-info-left bottom-border-mobile fail-red">Import/Export [FAIL]</h5>

<div className="cross-logo-mobile"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                                <h5 className="vehicle-info-left card-font-small">


                      <p>Car has been imported</p>
                      <p>Date imported:  01/08/2017</p>
                    </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left bottom-border-mobile pass-green">Stolen [PASS]</h5>

 <div className="tick-logo-mobile"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left card-font-small"><p>Car is not reported as stolen </p>
                      {/* <p>StolenMiaftrRecordCount:  </p>
                      <p>StolenPoliceForce: </p>
                      <p>StolenContactNumber:  </p>
                      <p>StolenDate: </p>
                      <p>StolenInfoSource:  </p>
                      <p>Stolen:  </p>
                                <p>StolenMiaftrRecordList: </p> */}
                              </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left bottom-border-mobile pass-green">Mileage [PASS]</h5>

 <div className="tick-logo-mobile"/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                              <h5 className="vehicle-info-left card-font-small">
                      <p>Mileage anomaly not detected</p>
                      <p>Mileage date:  01/03/2021</p>
                      <p>Mileage: 99,999 </p>
                      <p>Mileage source: MOT</p></h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left bottom-border-mobile">Car History</h5>
                      <div className="car-logo-report-mobile">

</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small">

                        <p>Number of plate changes: 1</p>


          <>
            <p>Plate Changes: </p>
            <p className="margin-left ">----------------</p>

              <>



              <p className="margin-left">Previous plate: BD68 SSS</p>
                <p className="margin-left">Change date: 05/10/2018</p>

                <p className="margin-left">----------------</p>
              </>

                        </>
                      </h5>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <div className="cards">
                <div className="row row-card-desktop desktop-padding">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm">
                  <div className="row">
                      <div className="col-sm">
                      <h5 className="vehicle-info-left bottom-border-mobile">Car Reference</h5>
                      <div className="car-logo-report-mobile">

                      </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <h5 className="vehicle-info-left card-font-small">

                        <p>Car has changed colour 0 times</p>
                      <p>Last 5 digits of the VIN: 09506 </p>
                                <p> Number of Previous Keepers: 0 </p>
                                <p>Last Change of Keeper: 11/12/2019</p>
                      <p>LatestV5cIssuedDate:  06/10/2017</p>
                      <p>Date first registered:  06/10/2017"</p>
                      <p>Year manufactured:  2017</p>
                      <p>Colour:  Blue</p>
                      <p>Fuel type: Diesel</p>
                      <p>Engine capacity: 1600cc</p>
                      <p>Transmission:  Manual</p>
                      <p>Number of gears:  6</p>
                    </h5>
                      </div>
                    </div>

                  </div>
                </div>
                    </div>



                  </>)}









                    </>

              {/* <div className="example-box">
                <p>Make: Volkswagen</p>
                <p>Model: Passat</p>
                <p>VRM: BD63SOX</p>
                <p>FinanceRecordCount: 1</p>
                <p> VehicleDescription: NEW PASSAT SALOON TDI 1 6L</p>
                <p> AgreementType: PCP</p>
                <p> AgreementDate: 06-10-2017</p>
                <p> AgreementTerm: 40 </p>
                <p> AgreementNumber: 2044359F </p>
                <p> FinanceCompany: Volkswagen Finance Services </p>
                <p> ContactNumber: 0845 0867111</p>
                <p> HighRiskRecordList: True </p>
                <p> HighRiskRecordCount: 0 </p>
                <p> WriteOffRecordCount: Warning </p>
                <p> WriteOffDate: ? </p>
                <p> Category: Category S </p>
                <p> LossType: CAT S STRUCTURAL DAMAGE </p>
                <p> VicTested: OK </p>



                <p>Scrapped: True</p>
                <p>ScrapDate: 14/03/2018</p>
                <p>CertificateOfDestructionIssued: True</p>
                <p>Imported: True</p>
                <p>Exported: True</p>
                <p>ImportDate: 01/08/2012</p>
                <p>ExportDate: OK </p>

                <p>ImportUsedBeforeUkRegistration: False</p>
                <p>StolenStatus: STOLEN</p>
                <p>StolenMiaftrRecordCount: 0</p>
                <p>StolenPoliceForce: Thames Valley Police</p>
                <p>StolenContactNumber: 0870 5275111</p>
                <p>StolenDate: 30/01/2018</p>
                <p>StolenInfoSource: Warning</p>
                <p>Stolen: STOLEN</p>
                <p>StolenMiaftrRecordList: Warning</p>
                <p>MileageAnomalyDetected: Warning</p>
                <p>Mileage: 100,200 </p>
                <p>DateOfInformation: 23/04/2020</p>
                <p>SourceOfInformation: MOT</p>
                <p>MileageRecordCount: 100,200</p>
                <p>ColourChangeCount: 0</p>


                <p>PreviousKeeperCount: 2</p>
                <p>LatestKeeperChangeDate: 02/02/2017</p>
                <p>PlateChangeCount: 1</p>
                <p>PreviousVrm: 874NMT</p>
                <p>DateChanged: 22/10/2015</p>
                <p>LatestV5cIssuedDate: 22/10/2018</p>
                <p>VinLast5: 09569</p>
                <p>DateFirstRegisteredUk: 02/02/2013</p>
                <p>YearOfManufacture: 01/01/2013</p>
                <p>Colour: Red</p>
                <p>FuelType: Petrol</p>
                <p>EngineCapacity: 1.6L</p>
                <p>TransmissionType: Manuel</p>
                <p>GearCount: 6</p>

                <p>LookupStatusCode: Need Relevant error messages</p>
                <p>LookupStatusMessage: Need Relevant error messages</p>


                    </div> */}





              <h2 className="top-gap">Guarantee </h2>
                    <p>The data we use for our History Check is constantly updated,
                    so we’re confident that it will identify any issues with a car
                    you’re thinking of purchasing. We offer up to £40,000 in
                    compensation if we knowingly get it wrong. Our full terms
                    and conditions are available here !!!!!LINK!!!!! and we try to make
                         them as clear as possible. </p>







        </div>
        </div>
    )
};



export default Full;
