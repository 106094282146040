import { combineReducers, createStore } from 'redux';
import { userReducer } from './userReducer';
import { vehicleReducer } from './vehicleReducer';
import { reducer as notificationsReducer } from 'reapop';





const rootReducer = combineReducers({
    user: userReducer,
    vehicle: vehicleReducer

});



export default rootReducer;