import React, {  useState,  useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import axios from 'axios';
import { auth} from '../../firebase';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const Register = ({history}) => {
    let dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [marketingAuth, setMarketingAuth] = useState(false);
    //PREVENT
    const { user } = useSelector((state) => ({ ...state }));


    useEffect(() => {
        if (user && user.token) history.push('/');
    }, [user, history]);


    const onSubmit = async e => {
        e.preventDefault();

        //valid
        if (password.length < 6) {
            toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">We need 6 characters for your password - we want to make sure your data is secure</div></div>,

            {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
            });
            return;
        }

        // if(e.target.marketingAuth.checked){
        //       setMarketingAuth(true)
        // }else{

        // }

        setMarketingAuth(true);
        try {
            // const result = await auth.signInWithEmailLink(email, window.location.href);
            let config = {
                url: `${process.env.REACT_APP_URL}/login`,
                handleCodeInApp: false
            }
            await auth.createUserWithEmailAndPassword(email, password);


            let user = auth.currentUser;
            user.sendEmailVerification(config);

            await user.updatePassword(password);


            history.push('/login');
            toast.info(<div><div className="car-contain"><div className="car-img" /></div><div className="car-img-text">We've sent you an email, it might
             take a few minutes to arrive. Check your spam folder if you can't see it soon. Click on the link in the email before you sign into your account</div></div>,

              {
              position: "top-center",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
              });

        } catch (error) {

            toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">{error.message}</div></div>,

          {
          position: "top-center",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
          });
        }
        try{

            const config = {
                headers: {
                  'Content-Type': 'application/json'
                }
              }

            const body = JSON.stringify({ displayName, email, phoneNumber, password, marketingAuth });

            const res = await axios.post(`${process.env.REACT_APP_API}api/user/register`, body, config);
        } catch (err) {

        }
    }





    return(
        <div className="containers">
            <div className="logincontain">
                <div className="regheader">
        <div class="regsignup">
        <h1 className="regsignuph">Sign Up</h1>
                    </div>
                    <div class="regsignin">
        <Link to='login'><h1 className="regsignuph">Sign In</h1></Link>
                </div>
                </div>


                <form onSubmit={e => onSubmit(e)}>
         <div className="form-group grey-input">
                <label>Email*</label>
                        <input type="email"
                            placeholer="johndoe@gmail.com"
                            name='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="form-control"
                />
            </div>
            <div className="form-group grey-input">
                <label>Display Name*</label>
                            <input type="text"

                    placeholer="john"
                    name='displayName'
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    minlength="1"
                                maxLength="18"
                                className="form-control"
                                required/>
            </div>

            <div className="form-group grey-input">
            <label> Mobile Number </label>

                        <PhoneInput
                    defaultCountry="GB"
                    value={phoneNumber}
                            onChange={setPhoneNumber}
                            />
            </div>
            <div className="form-group grey-input">
                <label>Password*</label>
                <input type="password"
                    placeholer="password"
                    name='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}

                    className="form-control"

                />
                </div>
                <div className="form-group grey-input">
                <label>Confirm Password*</label>
                <input type="password"
                    placeholer="confirm password"
                    name='password2'
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}

                    className="form-control"

                />
            </div>

            <div className="form-group">

                            {/* <input type="checkbox"
                                name='marketingAuth'
                                value={marketingAuth}
                                onChange={(e) => setMarketingAuth(true)}


                /> */}

                        <label>By creating an account you agree you’ve read and accepted our <a href='https://carxpert.co.uk/terms-and-conditions' target="_blank">terms and conditions</a>.

Please see our<a href='https://carxpert.co.uk/privacy-policy-2/' target="_blank"> privacy policy </a>for information regarding the processing of your data.</label>
            </div>

            <div className="form-group">
                        <input type="submit" value="Sign Up" className="btn btn-info" disabled={password !== password2}
                            />
            </div>



        </form>
        </div>
        </div>
    );
};



export default Register;
