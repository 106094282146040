import firebase from 'firebase'

const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FB_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FB_AUTH_DOMAIN}`,
    projectId: `${process.env.REACT_APP_FB_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FB_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FB_MSG_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FB_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FB_MEASURE_ID}`
  };

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
