import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from 'react-router-dom';
import firebase from 'firebase';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {NavDropdown} from "react-bootstrap";
import {useMediaQuery} from 'react-responsive';
import {HashLink as Linker} from 'react-router-hash-link';
import {toast} from 'react-toastify';
import { SITE_URL, BLOG_URL, CONTACTS_URL } from "../data/config";

const Navi = () => {
    let dispatch = useDispatch();
    let history = useHistory();
    let {user} = useSelector((state) => ({...state}));
    const nonAuthorizedMenu = [{
        title: 'Home',
        link: '/'
    }, {
        title: 'Sign In',
        link: '/login'
    }, {
        title: 'Sign Up',
        link: '/register'
    }];

    const authorizedMenu = [{
        title: 'Rate A Car',
        link: '/',
        isInternal: true,
    }, {
        title: 'Shortlist',
        link: '/shortlist',
        isInternal: true,
    }, {
        title: 'FAQs',
        link: '/#FAQ',
        isInternal: true,
        hash: true
    }, {
        title: 'Blog',
        link: BLOG_URL,
        isInternal: false,
    }, {
        title: 'Contact Us',
        link: CONTACTS_URL,
        isInternal: false,
    }, {
        title: 'Account Details',
        link: '/edit',
        isInternal: true,
    }, {
        title: 'Admin Dash',
        link: '/dashboard',
        isProtected: true,
        protection: user && user.email === 'admin@admin.com',
        isInternal: true,
    }, {
        title: 'Password Change',
        link: '/password',
        isInternal: true,
    }];

    const logout = () => {
        firebase.auth().signOut();

        dispatch({
            type: "LOGOUT",
            payload: null
        });

        dispatch({
            type: "LOGGED_OUT_HPI",
            payload: null
        });


        window.localStorage.removeItem('shortlist');
        window.localStorage.removeItem('score');
        window.localStorage.removeItem('emailForRegistration');
        toast.info(
            <div>
                <div className="car-contain">
                    <div className="car-img"/>
                </div>
                <div className="car-img-text">You've successfully logged out</div>
            </div>,

            {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });

        history.push('/login');
    };

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 768px)'})
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 768px)'
    })

    const renderHomeLinkButton = () => (
        <Link to='/' className="logo-home-link">
            <div className="">
                Rate a Car
            </div>
        </Link>
    );

    const renderLogo = () => (
        <a href={SITE_URL} className="logo">
            <div className="logolink"></div>
        </a>
    );

    const renderShortList = () => (
        <div className="shortlist-logo">
            <Link to='/shortlist'>
                <div className="shortlist-logo-icon"></div>
                <div className="shortlist-logo-text">Shortlist</div>
            </Link>
        </div>
    );

    const renderAuthorizedMenuItem = (item, i) => {
        return (
            <li className="bar" key={`authorized_menu_${i}`}>
                {renderMenuItem(item, i)}
            </li>
        )
    };

    const renderMenuItem = (item, i) => {
        if (!item.isInternal) {
            return (<a href={item.link}><p>{item.title}</p></a>)
        } else if (item.hash){
            return (<Linker smooth to={item.link}><p>{item.title}</p></Linker>)
        } else if (!item.hash && item.isInternal && !item.isProtected) {
            return (<Link to={item.link}><p>{item.title}</p></Link>)
        } else if (item.isProtected && item.protection) {
            return (<Link to={item.link}><p>{item.title}</p></Link>)
        }
    };

    const renderMobileMenu = () => {
        const closeSideBar = () => {
            document.getElementById("checkbox").checked = false
        }

        const renderMobileMenuItem = (item, i) => (
            <li key={`mobile_menu_${i}`} onClick={closeSideBar} className="bar">
                {renderMenuItem(item, i)}
            </li>
        );

        return (
            <div id="menuToggle">
                <input type="checkbox" id="checkbox"/>
                <span></span>
                <span></span>
                <span></span>
                {!user ? (
                    <ul id="menu">{nonAuthorizedMenu.map(renderMobileMenuItem)}</ul>
                ) : (
                    <ul id="menu">
                        {authorizedMenu.map(renderMobileMenuItem)}
                        <li onClick={logout}>
                            <Link to='/' onClick={closeSideBar}>
                                Log Out
                            </Link>
                        </li>
                    </ul>
                )}
            </div>
        )
    }

    return (
        <div>
            <nav className="navbar">
                {renderHomeLinkButton()}
                {renderLogo()}
                {(isTabletOrMobileDevice || isTabletOrMobile) && user && renderShortList()}
                {(isTabletOrMobileDevice || isTabletOrMobile) && renderMobileMenu()}
                {(isTabletOrMobileDevice === false && isTabletOrMobile === false)  && (
                    <div className="navcontain">
                        {user && renderShortList()}
                        {!user && (
                            <div className="links">
                                <div className="signin">
                                    <Link to='/login'>
                                        <div className="signinpic"></div>
                                        <p>Sign In </p>
                                    </Link>
                                </div>
                                <div className="signup">
                                    <p className="create-bold">Don't have an account?</p>
                                    <br/>
                                    <Link to='/register'>
                                        <p>Create an account now</p>
                                    </Link>
                                </div>
                            </div>
                        )}
                        {user && (
                            <div className="signin user">
                                <NavDropdown className="signinpic">
                                    {authorizedMenu.map(renderAuthorizedMenuItem)}
                                    <li onClick={logout} className="bar">
                                        <Link to='/'>
                                            <p>Log Out</p>
                                        </Link>
                                    </li>
                                </NavDropdown>
                                <p>Hello {user.displayName || 'there!'}  </p>
                            </div>
                        )}
                    </div>
                )}
            </nav>
        </div>
    );
};

export default Navi;
