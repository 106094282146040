import React, {  useState, useEffect } from 'react';
import '../../App.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const Dashboard = ({ history }) => {
    const dispatch = useDispatch()


    const { user } = useSelector((state) => ({ ...state }));
    const [fivestar, setFivestar] = useState("");
    const [fourstar, setFourstar] = useState("");
    const [threestar, setThreestar] = useState("");
    const [twostar, setTwostar] = useState("");

    //REJECT ANY NON-USERS AND NONE ADMIN - FOR MORE THAN 1 ADMIN, ADD ARRAY OF USERS ACCEPTED. BRIAN EXPECTS ONLY 1 WILL EVER BE NEEDED.
    if (user && user.email === "admin@admin.com") {

    } else {
        history.push('/');
    }

    //THIS ALLOWS BRIAN TO UPDATE SEGMENTATION THAT SCORE IS CALCULATED (BACKEND - ROUTES - VEHICLE - 18-21)
    const onSubmit = async e => {
        e.preventDefault();

        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
        }

        const body = ({ fivestar, fourstar, threestar, twostar });
        try {
            await axios.post(`${process.env.REACT_APP_API}api/vehicle/admin`, body, config);
        }
        catch (err) {

        }
        history.push('/');
    }

        return(
            <div className="container">
            <div className="logincontain">
               <div class="page-header">
                  <h1 className="regsignuph">Dashboard</h1>
                  <h5>Entered value will be the less-than cut off.</h5>
                  <h6>For example, 4 stars value = 67, means all values less than 67 will become 4 stars.</h6>
                  <h6>The 1 star value will always be more than the 2 stars value.</h6>
               </div>
               <form onSubmit={e =>
                  onSubmit(e)}>
                  <div className="form-group grey-input">
                     <label>5 stars value</label>
                     <input type="number"
                        placeholder={fivestar}
                        name='fivestar'
                        value={fivestar}
                        onChange={(e) => setFivestar(e.target.value)}
                     className="form-control"
                     required
                     />
                  </div>
                  <div className="form-group grey-input">
                     <label>4 stars value</label>
                     <input type="number"
                        name='fourstar'
                        value={fourstar}
                        onChange={(e) => setFourstar(e.target.value)}
                     className="form-control"
                     required
                     />
                  </div>
                  <div className="form-group grey-input">
                     <label> 3 stars value</label>
                     <input type="number"
                        name='threestar'
                        value={threestar}
                        onChange={(e) => setThreestar(e.target.value)}
                     className="form-control"
                     />
                  </div>
                  <div className="form-group grey-input">
                     <label> 2 stars value</label>
                     <input type="number"
                        name='twostar'
                        value={twostar}
                        onChange={(e) => setTwostar(e.target.value)}
                     className="form-control"
                     />
                  </div>
                  <div className="form-group">
                     <input type="submit" value="Submit Changes" className="btn btn-info"
                        />
                  </div>
               </form>
            </div>
         </div>
    );
};



export default Dashboard;
