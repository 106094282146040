import React, {  Fragment, useState, useEffect } from 'react';
import Axios from 'axios';
import { toast } from 'react-toastify';
import HowItWorks from '../components/HowItWorks.jsx';
// import  { Redirect } from 'react-router-dom'
import Big4 from '../img/Large_good.svg';
import { useDispatch } from 'react-redux';
import Hpi from '../img/hpi.svg';
import { getUser, getCar } from '../functions/user';
import { Accordion, Card } from "react-bootstrap";
import diagram from '../img/home-image.png';
import { useLocation } from "react-router-dom";
import ReactLoading from 'react-loading';
import styled from "styled-components";

const HelpText = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;

  p{
    margin: 0;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    &{
      width: 60%;

      p{
        font-size: 13px;
      }
    }
  }
`
const Landing = ({ history }) => {
    const { pathname } = useLocation();
    let dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#ffcc00");
    let [type, setType] = useState("bars");
    const [formData, setFormData] = useState({
        vehicleRegistration: ''
    });


    useEffect(() => {

        dispatch({
            type: 'LOGGED_HPI',
            payload: null
        });

    }, [pathname]);



    let { vehicleRegistration } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        const regString = vehicleRegistration.replace(/\s/g, '');
        history.push('/score/' + regString);
    }

  return (
    <Fragment>
         <>
      { loading ? (<ReactLoading className="loading-screen" type={type} color={color} height={667} width={375} />) : (<></>)}
   <section className='landing'>
      <div className='dark-overlay'>
         <div className='landing-inner'>
            <div className="contain-landing">
                <h3 className="search-text"> Interested in a car? <br />
                <br/> Rate it for free<br /> Enter a registration below to begin*</h3>
                <HelpText>
                    <p>Reg numbers are usually 2 letters, 2 numbers, 3 letters - like AB02ABC</p>
                </HelpText>
               <div className="arrows-contain">
                  <div className="arrows"></div>
               </div>
               <form onSubmit={e =>
                  onSubmit(e)}>
                  <div className="form-group">
                  <div className="reg-plate">
                  <div className="i-logo-home"></div> 
                        <input type="text"
                           autoComplete="off"
                           name='vehicleRegistration'
                           onChange={e => onChange(e)}
                        value={vehicleRegistration.toUpperCase()}
                        required
                        maxLength="8"
                        />
                     </div>
                     <div className="reg-button-cont">
                         <input
                             type="submit"
                             value="Get Rating"
                             className="reg-button"
                         />
                    </div>
                    <h6 className="search-text-bottom">*Cars with MOT history in use in England, Scotland and Wales</h6>
                  </div>
              </form>
              
              
              

            </div>
            <HowItWorks />
            <div className="jumbotron gap blank">

                                {/*
              DO NOT DELETE THIS!!

              CLIENT NEEDS TO SEND CONTENT, BUT REMOVED FOR TEMP ASTHETIC



              <div className="container">
                <div className="ad">
                  <div className="centre"><h1>ADVERT SPACE</h1></div>
                </div>
              </div>

              <div className="container">
                <div className="header">
                    <h1>What we provide you with</h1>
                </div>
              </div> */}

                                <div className="backgr">
                                    {/* <div className="container">
                <div className="row">
                    <div className="col-sm">
                      <div className="row">
                        <div className="col-sm">
                        <h5>Fails</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <img src={Big4} width="80%" alt="score"/>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                      aliquam erat volutpat.</p>
                        </div>
                      </div>

                    </div>

                    <div className="col-sm">
                    <div className="row">
                        <div className="col-sm">
                        <h5>Overall</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <img src={Big4} width="80%" alt="score"/>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                      aliquam erat volutpat.</p>
                        </div>
                      </div>
                    </div>


                    <div className="col-sm">
                    <div className="row">
                        <div className="col-sm">
                        <h5>Advisories</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <img src={Big4} width="80%" alt="score"/>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                      aliquam erat volutpat.</p>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>


              <div className="container margin">
                <div className="row">
                    <div className="col-sm"><div className="row">
                        <div className="col-sm">
                        <h5>Enquiry Assistant</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <img src={Big4} width="80%" alt="score"/>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                      aliquam erat volutpat.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm"><div className="row">
                        <div className="col-sm">
                        <h5>Heading 2</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <img src={Big4} width="80%" alt="score"/>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                      aliquam erat volutpat.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm"><div className="row">
                        <div className="col-sm">
                        <h5>HPI Check</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <img src={Big4} width="80%" alt="score"/>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                      aliquam erat volutpat.</p>
                        </div>
                      </div>
                    </div>
                </div>

              </div> */}



                                    <div id="FAQ">
                                    </div>
                                    <div className="contrainer margin faq">
                                        <div className="header">
                                            <h1>FAQ</h1>
                                        </div>
                                        <div className="accord">
                                            <div className="accord-contain">
                                                <Accordion >
                                                    <Card className="colourr">
                                                        <Accordion.Toggle as={Card.Header} eventKey="0" className="colour">
                                                            <h5 className="centre">How do CarXpert’s ratings work? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="0" className="colour">
                                                            <Card.Body><p className="home-font">We’ve analysed the MOT history of huge numbers of cars so we can tell how individual cars compare in terms of issues that were found when they had MOT tests.
                                                                If you give us the registration number of a car that you’re interested in, we can tell you how good its MOT history is compared to similar cars.
                                                            </p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="1" className="colour">
                                                            <h5 className="centre">What are Failures and Advisories? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="1" className="colour">
                                                            <Card.Body><p>MOT issues are categorised in terms of how serious they are. Failures
                                                                are issues that meant the car failed a MOT test. Advisories are issues the tester thought
                                                                the owner needed to be aware of. The latest information on how DVSA categorises issues found
                                                                during MOT tests can be found here.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    {/*<Card className="colour">*/}
                                                    {/*    <Accordion.Toggle as={Card.Header} eventKey="2" className="colour">*/}
                                                    {/*    <h5 className="centre">Do I need to do any other checks? <div className="arrow-down"></div></h5>*/}
                                                    {/*    </Accordion.Toggle>*/}

                                                    {/*    <Accordion.Collapse eventKey="2" className="colour">*/}
                                                    {/*    <Card.Body><p>MOT history gives you a good indication of the mechanical condition of a car,*/}
                                                    {/*    especially safety-related items, and how well it’s been maintained. It’s also worth investing*/}
                                                    {/*    in a full vehicle history check to make sure that a car you’re looking at hasn’t been involved in*/}
                                                    {/*        accidents, stolen, or has unpaid finance. You can get one of these checks here (link).</p>*/}
                                                    {/*    </Card.Body>*/}
                                                    {/*    </Accordion.Collapse>*/}
                                                    {/*</Card >*/}

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="3" className="colour">
                                                            <h5 className="centre">Should I worry about Advisories? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="3" className="colour">
                                                            <Card.Body><p>Advisories, (or Minor issues), are items that won’t make a car fail an MOT,
                                                                but that the owner should be aware of, e.g., tyres that are legal but getting low on tread,
                                                                brake pads that are getting low, or an oil leak. If a car has a poor rating for advisories
                                                                it might indicate it hasn’t been maintained well, so the Advisory rating is worth taking
                                                                into consideration.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="4" className="colour">
                                                            <h5 className="centre">What’s the Enquiry Assistant? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="4" className="colour">
                                                            <Card.Body><p>There are some dodgy sellers out there as well as dodgy cars,
                                                                and the Enquiry Assistant gives you the questions you need to ask when you
                                                                first enquire about a used car to avoid common problems that can come up
                                                                with sellers and cars. The Enquiry Assistant is here (link).</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    {/*                        <Card className="colour">*/}
                                                    {/*                            <Accordion.Toggle as={Card.Header} eventKey="5" className="colour">*/}
                                                    {/*                            <h5 className="centre">What’s a vehicle history check and when should I get one? <div className="arrow-down"></div></h5>*/}
                                                    {/*                            </Accordion.Toggle>*/}

                                                    {/*                            <Accordion.Collapse eventKey="5" className="colour">*/}
                                                    {/*                            <Card.Body><p>A vehicle history check will give you important information about a car, e.g., whether the car has been written*/}
                                                    {/*                            off, is stolen, or has outstanding finance.  The cost is very low compared to the costs you could incur buying a car with a bad*/}
                                                    {/*                            history.*/}
                                                    {/*                            The price of history checks has come down, and with the cost of fuel and the complications caused by Covid when viewing cars,*/}
                                                    {/*                             we would recommend getting a vehicle history check for any car you are thinking of viewing. You can get Vehicle history checks*/}
                                                    {/*                              here (link).*/}
                                                    {/*</p></Card.Body>*/}
                                                    {/*                            </Accordion.Collapse>*/}
                                                    {/*                        </Card>*/}

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="6" className="colour">
                                                            <h5 className="centre">What else can I do to make sure I don’t end up with unexpected costs? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="6" className="colour">
                                                            <Card.Body><p>While you can look at a car’s MOT history and do other research into a car before you buy it, things can still go wrong, and modern cars can be very expensive to fix. The government’s Money Advice Service has published a helpful guide to car warranties here
                                                                <a target="_blank" href="https://www.moneyadviceservice.org.uk/en/articles/car-warranties"> www.moneyadviceservice.org.uk/en/articles/car-warranties
                                                                </a>.
                                                            </p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="7" className="colour">
                                                            <h5 className="centre">Why should I create an account? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="7" className="colour">
                                                            <Card.Body><p>Anyone can use CarXpert to get MOT ratings for free, but if you want to
                                                                create a shortlist (see “Why should I make a shortlist (link) you’ll need to create a free account.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>



                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="8" className="colour">
                                                            <h5 className="centre">Why should I care about a car’s MOT history? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="8" className="colour">
                                                            <Card.Body><p className="home-font">A car that has been well maintained is likely to
                                                                cost you less money in the future than one that’s been neglected, so by choosing a car
                                                                with a good MOT history you’re less likely to have unexpected bills.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="9" className="colour">
                                                            <h5 className="centre">Why do the ratings depend on the age of the car? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="9" className="colour">
                                                            <Card.Body><p>As cars get older and have more wear and tear then they tend to have more issues. We adjust the
                                                                ratings to take the age of the cars into account. That way you can tell which cars truly have the best
                                                                MOT histories.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="00" className="colour">
                                                            <h5 className="centre">What do the different ratings mean? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="00" className="colour">
                                                            <Card.Body><p>• The Failures rating shows how a car compares in terms of issues that can lead to a car failing an MOT test.
                                                                • The Advisory rating shows how a car compares in terms of the number of issues that would not lead to a MOT failure but would be reported to the owner.
                                                                • The overall rating shows how a car’s MOT history compares across both Failures and Advisories.
                                                            </p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="11" className="colour" >
                                                            <h5 className="centre">Can I see the detailed MOT history for a car? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="11" className="colour">
                                                            <Card.Body><p>Yes - if you want to look at a car’s complete MOT history, with all the Failures and Advisories that have been recorded, you can see it once you’ve rated a car.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="22" className="colour">
                                                            <h5 className="centre">Do I need to bother asking all those questions? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="22" className="colour">
                                                            <Card.Body className="colour"><p>There are quite a few questions in the Enquiry Assistant
                                                                as there are many things that can catch you out when buying a used car. The Enquiry Assistant will
                                                                limit the number of questions as much as possible and will also show you which questions are most
                                                                important.
                                                                The aim is to help make sure you don’t waste time and money travelling to view a car that isn’t as
                                                                described – or even worse, ending up purchasing one. Time invested in some questions could save you
                                                                a lot of money.
                                                            </p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="33" className="colour">
                                                            <h5 className="centre">Why should I make a shortlist? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="33" className="colour">
                                                            <Card.Body className="colour"><p>The shortlist lets you store key information about cars like the MOT history rating,
                                                                so you can easily see which of the cars you are considering have the best MOT histories. You can sort your shortlist
                                                                on things like price, mileage, or MOT History rating, and you can remove or add cars whenever you want.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="44" className="colour">
                                                            <h5 className="centre">What if a car’s too young to need a MOT yet? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="44" className="colour">
                                                            <Card.Body><p>If a car is under three years old (four years old in Northern Ireland)
                                                                then it doesn’t need a MOT, so we can’t give it a MOT history rating. However, you
                                                                can use some other CarXpert tools to help you make the right purchase – the Enquiry
                                                                Assistant (link), and a vehicle history check (link).</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="55" className="colour">
                                                            <h5 className="centre">What if the advert for the car doesn’t show the registration number? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="55" className="colour">
                                                            <Card.Body><p>If the advert doesn’t show the registration number you should
                                                                contact the seller, explaining that you want it so that you can check the MOT
                                                                history. Most sellers will be happy to give you the registration number.
                                                                If they won’t give you the registration then we’d suggest you don’t proceed any
                                                                further as you won’t be able rate the MOT history or get a full vehicle check.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>


                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="66" className="colour">
                                                            <h5 className="centre">What about Classic Cars?<div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="66" className="colour">
                                                            <Card.Body><p>As of May 20, 2018, nearly all cars that were built more
                                                                than 40 years ago are exempt from the annual MOT test. Previously, only cars
                                                                first registered before 1960 were exempt.
                                                                So, cars over forty years old are likely to have gaps in their MOT history where
                                                                owners have used the car’s MOT exemption. CarXpert ratings should still help you
                                                                identify which classic cars have better MOT histories, but you should also look at
                                                                the car’s MOT history itself.
                                                            </p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>


                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="77" className="colour">
                                                            <h5 className="centre">The car seems to have been late for its MOT  <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="77" className="colour">
                                                            <Card.Body><p>CarXpert will identify if a car has had fewer MOTs than expected,
                                                                possibly because it was traded in just before its MOT was due and didn’t get
                                                                a new MOT until a dealer sold the car. However, it might be because the owner
                                                                didn’t take the car in for its MOT or because the car was off the road for a
                                                                while having accident damage repaired.  We would strongly recommend that if
                                                                you’re considering a car like this you carry out a full vehicle check [link].</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>



                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="88" className="colour">
                                                            <h5 className="centre">The car doesn’t seem to have been MOT’d every year<div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="88" className="colour">
                                                            <Card.Body><p>There are several possible reasons for this. It could be that
                                                                the owner was forgetful or didn’t see car maintenance as a priority, the car is a
                                                                classic (more information here [link]), or the car may have been exported or off the
                                                                road in storage. We would strongly recommend that if you’re considering a car like this
                                                                you carry out a full vehicle check [link].</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>


                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="99" className="colour">
                                                            <h5 className="centre">The car seems to have had more than one MOT a year, why might that be?<div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="99" className="colour">
                                                            <Card.Body><p>A car may have had more than one MOT in a year because it was traded in and then
                                                                sold on with a full year’s MOT. It might also be because the car was used as a taxi or minicab
                                                                – these must be tested more frequently. We would strongly recommend that if you’re considering
                                                                a car like this you carry out a full vehicle check [link].</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>



                                                    {/*<Card className="colour">*/}
                                                    {/*    <Accordion.Toggle as={Card.Header} eventKey="101" className="colour">*/}
                                                    {/*    <h5 className="centre">The car’s annual mileage seems to have varied a lot<div className="arrow-down"></div></h5>*/}
                                                    {/*    </Accordion.Toggle>*/}

                                                    {/*    <Accordion.Collapse eventKey="101" className="colour">*/}
                                                    {/*    <Card.Body><p>Big annual mileage variations can be genuine, e.g., a car’s*/}
                                                    {/*    first owner does a lot of miles for work, but the second owner only uses it*/}
                                                    {/*    for short journeys. However, it can also be caused by an unscrupulous owner*/}
                                                    {/*    or trader reducing the recorded mileage – “clocking” as it’s often termed.*/}
                                                    {/*    We would strongly recommend that if you’re considering a car like this you*/}
                                                    {/*         carry out a full vehicle check [link]. </p></Card.Body>*/}
                                                    {/*    </Accordion.Collapse>*/}
                                                    {/*</Card>*/}



                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="111" className="colour">
                                                            <h5 className="centre">How far back does the MOT history go? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="111" className="colour">
                                                            <Card.Body><p>MOTs started being recorded online in 2005, with all MOTs gradually moving online by 2006.</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>

                                                    {/*<Card className="colour">*/}
                                                    {/*    <Accordion.Toggle as={Card.Header} eventKey="222" className="colour">*/}
                                                    {/*    <h5 className="centre">Is the mileage recorded on the MOT reliable? <div className="arrow-down"></div></h5>*/}
                                                    {/*    </Accordion.Toggle>*/}

                                                    {/*    <Accordion.Collapse eventKey="222" className="colour">*/}
                                                    {/*    <Card.Body><p>The mileage recorded at each MOT is based on what is indicated on the car’s odometer.*/}
                                                    {/*    If someone has been careful to avoid suspicion when they’ve clocked a car, we won’t be able to*/}
                                                    {/*    identify it from the MOT data. Also, if a car was clocked before its first MOT then that can’t be*/}
                                                    {/*    spotted in the MOT history. Before you decide to buy a car you should get a full vehicle check to*/}
                                                    {/*       help identify any issues with its mileage – and you can get one here – [link].</p></Card.Body>*/}
                                                    {/*    </Accordion.Collapse>*/}
                                                    {/*</Card>*/}

                                                    <Card className="colour">
                                                        <Accordion.Toggle as={Card.Header} eventKey="333" className="colour">
                                                            <h5 className="centre">Can I get a rating for a car in Northern Ireland, or the Isle of Man? <div className="arrow-down"></div></h5>
                                                        </Accordion.Toggle>

                                                        <Accordion.Collapse eventKey="333" className="colour">
                                                            <Card.Body><p>CarXpert can provide ratings for cars that are in use in England, Scotland, and Wales,
                                                                but not for Northern Ireland or the Isle of Man. It’s not possible to access MOT history data for
                                                                MOT tests carried out in Northern Ireland, and there is no requirement for an annual roadworthiness
                                                                test like an MOT in the Isle of Man..</p></Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>



                        </div>
                    </div>
                </section>
            </>
        </Fragment>
    );
};


export default Landing;
