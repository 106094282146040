import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';


//POSSIBLE REDUNDANT COMPONENT. WSA USED TO STOP REACT PAGES LOADING HALF WAY DOWN PAGE (Standard React)
function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);