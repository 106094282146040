import React, {  useState } from 'react';
import '../../App.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {Link} from 'react-router-dom';



//THIS FUNCTIONAL COMPONENT INTERCEPTS SOCIAL MEDIA LOG IN USERS TO MAKE SURE THEY ACCEPT/DECLINE MARKETING AUTH
const Marketing = ({history}) => {

  const [formData, setFormData] = useState({
    vehicleRegistration: ''
  });

  const { user } = useSelector((state) => ({ ...state }));

    async function accept() {

        const email = user.email;


        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
        }
        const marketingAuth = true;

          const body = JSON.stringify({ email, marketingAuth });

          try {
            await axios.post(`${process.env.REACT_APP_API}api/user/update/`, body, config);
          }
          catch (error) {
          }

        history.push("/");
    }

    async function decline() {

        const email = user.email;

        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
        }
        const marketingAuth = false;

          const body = JSON.stringify({ marketingAuth });

          try {
            await axios.post(`${process.env.REACT_APP_API}api/user/update/` + email, body, config);
          } catch (error) {

          }

        history.push("/");
    }






  return (
      <div className="container">
        <div className="editcontain">
            <div class="page-header">
              <h1>Marketing Preference</h1>
            </div>
            <p>Are you happy for us to use your data for marketing purposes?</p>
            <div className="form-group">
              <input type="submit" value="Accept" onClick={() => accept() }className="btn btn-info"
              />
              <input type="submit" value="Decline"  onClick={() => decline() }className="btn btn-warning"
              />
            </div>
        </div>
      </div>
  );
};


export default Marketing;
