import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import {auth} from '../firebase';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import ReactLoading from 'react-loading';
import PhoneInput from 'react-phone-number-input';


const Edit = ({history}) => {
    const dispatch = useDispatch()


    const {user} = useSelector((state) => ({...state}));
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [marketingAuth, setMarketingAuth] = useState("");
    const [checked, setChecked] = useState("");
    let [color, setColor] = useState("#ffcc00");
    let [type, setType] = useState("bars");
    const [loading, setLoading] = useState(false);

    if (!user) {
        history.push('/')
    }

    useEffect(() => {
        setEmail(user.email)
        setDisplayName(user.displayName)
        setPhoneNumber(user.phoneNumber)
        setMarketingAuth(user.marketingAuth)
    }, []);


    //SIMPLE PING TO BACKEND FOR USER EDIT
    const onSubmit = async e => {
        setLoading(true);
        e.preventDefault();

        //MARKETING DATABASE
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({
            firstName,
            lastName,
            displayName,
            email,
            phoneNumber,
            marketingAuth
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API}api/user/update/`, body, config);

            dispatch({
                type: 'LOGGED_IN_USER',
                payload: {
                    email,
                    displayName,
                    phoneNumber,
                    marketingAuth
                }
            })


        } catch (error) {

        }


        history.push('/');
    }

    return (
        <>
            {loading ? (
                <ReactLoading className="loading-screen" type={type} color={color} height={667}
                              width={375}/>
            ) : (<></>)}
            <div className="logincontain">
                <div class="page-header">
                    <h1 className="regsignuph">Edit Account</h1>
                </div>
                <form onSubmit={e =>
                    onSubmit(e)}>
                    <div className="form-group grey-input">
                        <label>Email*</label>
                        <input type="text"
                               name='email'
                               disabled
                               onChange={(e) => setEmail(e.target.value)}
                               value={email}
                               className="form-control"
                        />
                    </div>
                    <div className="form-group grey-input">
                        <label>Display Name*</label>
                        <input type="text"
                               placeholer="john"
                               name='displayName'
                               value={displayName}
                               onChange={(e) => setDisplayName(e.target.value)}
                               minlength="2"
                               maxLength="18"
                               className="form-control"
                               required
                        />
                    </div>
                    <div className="form-group grey-input">
                        <label> Mobile Number </label>
                        <PhoneInput
                            defaultCountry="GB"
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                        />
                    </div>
                    {user && user.marketingAuth === true && (
                        <>
                            <div className="form-group">
                                <input type="checkbox"
                                       name='marketingAuth'
                                       value={marketingAuth}
                                       onChange={(e) => setMarketingAuth(false)}
                                       defaultChecked={checked}
                                />
                                <a href='https://carxpert.co.uk/terms-and-conditions/' target="_blank">
                                    Terms and Conditions
                                </a>
                            </div>
                        </>
                    )}
                    {user && user.marketingAuth !== true && (
                        <>
                            <div className="form-group terms-conditions">
                                <input type="checkbox"
                                       name='marketingAuth'
                                       value={marketingAuth}
                                       onChange={(e) => setMarketingAuth(true)}
                                       defaultChecked={checked}
                                />
                                <label>
                                    <a href='https://carxpert.co.uk/terms-and-conditions/' target="_blank">
                                        Terms and Conditions
                                    </a>
                                </label>
                            </div>
                        </>
                    )}
                    <div className="form-group">
                        <input type="submit" value="Submit Changes" className="btn btn-info"
                        />
                    </div>
                </form>
            </div>
        </>
    );
};


export default Edit;
