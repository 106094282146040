import React, {useState, useEffect} from 'react';
import '../App.css';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import {useParams} from "react-router";
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
//IMAGE IMPORTS
import Small1 from '../img/Small_Poor.svg';
import Big1 from '../img/Large_Poor.svg';
import Small2 from '../img/Small_WAvg.svg';
import Big2 from '../img/Large_WAvg.svg';
import Small3 from '../img/Small_Avg.svg';
import Big3 from '../img/Large_Avg.svg';
import Small4 from '../img/Small_Good.svg';
import Big4 from '../img/Large_good.svg';
import Small5 from '../img/Small_Pristine.svg';
import Big5 from '../img/Large_Pristine.svg';
import Car from '../img/Cars.svg';
import Hpi from '../img/hpi.svg';
import {getCar, getUser} from '../functions/user';
import ReactLoading from 'react-loading';
import {Accordion, Card} from "react-bootstrap";
import {flexbox} from '@material-ui/system';
import Axios from "axios";
import MOTHistory from "../components/MOTHistory";


const Score = ({history}) => {
    const dispatch = useDispatch();

    const {id} = useParams();
    if (id) {
        window.localStorage.setItem('score', id.toUpperCase());
    } else {
        if (!window.localStorage.getItem('score')) history.push('/');
    }

    const getVehicleInfo = async () => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const body = JSON.stringify({
                vehicleRegistration: id
            });

            return await Axios.post(`${process.env.REACT_APP_API}api/vehicle/score`, body, config);
        } catch (error) {
            setLoading(false);
            const  errorMessage = error && error.response && error.response.data;

            toast.error(<div>
                    <div className="car-contain">
                        <div className="car-img-error"/>
                    </div>
                    <div className="car-img-text">{errorMessage}</div>
                </div>,

                {
                    position: "top-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            history.push('/');
        }
    }


    const [vehicleRegistration, setVehicleRegistraion] = useState("");
    const [_id, set_Id] = useState("");
    const [paramid, setParamid] = useState(useParams());
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [vehicleColour, setVehicleColour] = useState("");
    const [vehicleEngine, setVehicleEngine] = useState("");
    const [vehicleAvgInfo, setVehicleAvgInfo] = useState("");
    const [vehicleAvgFail, setVehicleAvgFail] = useState("");
    const [vehicleLastMot, setVehicleLastMot] = useState("");
    const [vehicleFuel, setVehicleFuel] = useState("");
    const [vehicleMileage, setVehicleMileage] = useState("");
    const [vehicleYear, setVehicleYear] = useState("");
    const [save, setSave] = useState("");
    const [car, setCar] = useState([]);
    const [list, setList] = useState({});
    const [torf, setTorf] = useState("FALSE");
    const [fullcheck, setFullcheck] = useState("");
    const [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffcc00");
    const [css, setCss] = useState("");
    const [score, setScore] = useState({})

    const {user} = useSelector((state) => ({...state}));


    useEffect(() => {
        setLoading(true);
        setCss("colourrrr");
        getVehicleInfo(id)
            .then((res) => {
                if (res && res.data) {
                    setVehicleRegistraion(res.data.vehicleRegistration);
                    set_Id(res.data._id);
                    setVehicleMake(res.data.vehicleMake);
                    setVehicleModel(res.data.vehicleModel);
                    setVehicleColour(res.data.vehicleColour);
                    setVehicleEngine(res.data.vehicleEngine);
                    setVehicleAvgInfo(res.data.vehicleAvgInfo);
                    setVehicleAvgFail(res.data.vehicleAvgFail);
                    setVehicleLastMot(res.data.vehicleLastMot);
                    setVehicleMileage(res.data.vehicleMileage);
                    setVehicleYear(res.data.vehicleYear);
                    setVehicleFuel(res.data.vehicleFuel);
                    setCar(res.data.motTests);
                    setLoading(false);
                } else {
                    history.push('/');
                }
            })
            .catch((err) => {

                toast.error(<div>
                        <div className="car-contain">
                            <div className="car-img-error"/>
                        </div>
                        <div className="car-img-text">{err.message}</div>
                    </div>,

                    {
                        position: "top-center",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });

                history.push('/');
            });

        if (window.localStorage.getItem('emailForRegistration')) {
            getUser(window.localStorage.getItem('emailForRegistration'))
                .then((res) => {
                    user.checks = res.data.checks;
                })
                .catch((err) => {

                });
        }

        try {
            var body = axios
                .get(`${process.env.REACT_APP_API}api/vehicle/admin`)
                .then(body => (setScore(body.data)));
        } catch (err) {

        }

        short();
    }, []);


    function toastme() {
        window.localStorage.setItem('shortlist', _id);

        toast.error(<div>
                <div className="car-contain">
                    <div className="car-img-error"/>
                </div>
                <div className="car-img-text">You need to be signed in to save a car to your shortlist
                </div>
            </div>,

            {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
    }

    async function setting() {

        try {
            if (!!user?.email) {
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

                const vehicleRegistration = window.localStorage.getItem('score');

                const body = ({vehicleRegistration, email: user?.email});

                var result = await axios.post(`${process.env.REACT_APP_API}api/vdi/user/`, body, config);

                if (result.data === "") {
                    dispatch({
                        type: 'LOGGED_HPI',
                        payload: {
                            vehicleRegistration
                        }
                    })
                } else {
                    setFullcheck(result.data);
                }
            }
        } catch (err) {

        }

        return getUser();
    }

    async function short() {

        let res = await setting();

        if (!!res?.data) {
            setTimeout(function () {
                for (let r = 0; r < res.data.short.length; r++) {
                    let checker = window.localStorage.getItem('score');

                    if (checker && checker.includes(' ')) {
                        let arr = checker.split(' ');
                        checker = arr.join('');
                    }

                    if (res.data.short[r].vehicleRegistration === checker) {
                        setTorf("TRUE");
                    }
                }
            }, 200);
        }
    }

    var info;
    var astar;
    if (vehicleAvgInfo <= score.fivestar) {
        info = Big5;
        astar = 5;
    } else if (vehicleAvgInfo < score.fourstar) {
        info = Small4;
        astar = 4;
    } else if (vehicleAvgInfo < score.threestar) {
        info = Small3;
        astar = 3;
    } else if (vehicleAvgInfo < score.twostar) {
        info = Small2;
        astar = 2;
    } else {
        info = Small1;
        astar = 1;
    }

    var fail;
    var fstar;
    if (vehicleAvgFail <= score.fivestar) {
        fail = Big5;
        fstar = 5;
    } else if (vehicleAvgFail < score.fourstar) {
        fail = Small4;
        fstar = 4;
    } else if (vehicleAvgFail < score.threestar) {
        fail = Small3;
        fstar = 3;
    } else if (vehicleAvgFail < score.twostar) {
        fail = Small2;
        fstar = 2;
    } else {
        fail = Small1;
        fstar = 1;
    }

    var overall = (astar + fstar) / 2;
    var over;
    if (overall === 5) {
        over = Big5;

    } else if (overall >= 4) {
        over = Big4;
    } else if (overall >= 3) {
        over = Big3;
    } else if (overall >= 2) {
        over = Big2;
    } else {
        over = Big1;
    }

    function disp() {
        dispatch({
            type: 'LOGGED_HPI',
            payload: {
                fullcheck,
                vehicleRegistration
            }
        })


        history.push('/vdi-check');
    }

    const onSubmit = async e => {
        e.preventDefault();
        setSave(true);

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const email = user.email;
        const body = JSON.stringify({email, _id});

        try {
            await axios.post(`${process.env.REACT_APP_API}api/shortlist/add`, body, config);
        }
        catch (err) {

        }

    }

    const renderLoading = () => (loading ?
        <ReactLoading
            className="loading-screen"
            type="bars" color={color}
            height={667}
            width={375}/> : ''
    )

    const getExpireDate = () => {
        if (!car || !car.length) {
            return '';
        }

        const mostRecentMOTTest = car[0];

        if (!mostRecentMOTTest.expiryDate) {
            return '';
        }

        const expiry = moment(mostRecentMOTTest.expiryDate, 'YYYY.MM.DD');
        const expiryDate = expiry.format('DD/MM/YYYY');

        return expiryDate;
    }

    const renderCarDetailsRow = (arg1, arg2) => {
        return (
            <div className="row car-details-row">
                <div className="col-6">
                    <h6>{arg1}</h6>
                </div>
                <div className="col-6">
                    <h6>{arg2}</h6>
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderLoading()}
            <div className="container-score">
                <div className="scorebg">
                    <div className="row">
                        <div className="col-5 under">
                            <h1 className="regsignuph">Ratings for </h1>
                        </div>
                        <div class="col-7 underr">
                            <div className="reg-background">
                                <h2 className="score-reg">
                                    {vehicleRegistration ? vehicleRegistration.toUpperCase() : ''}
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* SCORE ROW */}
                    <div class="row">
                        <div class="col-5 col-md-4">
                            <div className="row sta">
                                <div className="col-sm">
                                    <div className="starsimage">
                                        <h6>Overall</h6>
                                        <img src={over} alt="score"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row sta">
                                <div className="col-sm">
                                    <div className="starsimage">
                                        <h6>Fails</h6>
                                        <img src={fail} alt="score"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row sta">
                                <div className="col-sm">
                                    <div className="starsimage">
                                        <h6>Advisories</h6>
                                        <img src={info} alt="score"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* LOGGED IN */}
                        <div className="col-7 car-details-table ant-col-md-offset-2">
                            <div className="car-details-table-round">
                                {renderCarDetailsRow('Make', vehicleMake)}
                                {renderCarDetailsRow('Model', vehicleModel)}
                                {renderCarDetailsRow('Engine', vehicleEngine)}
                                {renderCarDetailsRow('Fuel', vehicleFuel)}
                                {renderCarDetailsRow('MOT expiry', getExpireDate())}
                            </div>
                        </div>
                    </div>


                    <div class="row car-details-buttons">
                        <div class="col-6">
                            <div className="container-login">
                                <div className="row sta">
                                    <div className="col-sm btn-left">
                                        <form onSubmit={e =>
                                            onSubmit(e)}>

                                            {torf === "FALSE" && (
                                                <>

                                                    {save && (
                                                        <input value="✓ Saved to shortlist"
                                                               className="btn btn-primary"
                                                               type="button"
                                                        />
                                                    )}

                                                    {!save && user && (
                                                        <input onClick={onSubmit}
                                                               value="Save to shortlist"
                                                               className="btn btn-primary"
                                                               type="button"
                                                        />
                                                    )}
                                                    {!user && (
                                                        <Link to='/login'>
                                                            <input value="Save to shortlist"
                                                                   className="btn btn-primary"
                                                                   onClick={toastme} type="button"
                                                            />
                                                        </Link>
                                                    )}
                                                </>

                                            )}

                                            {torf === "TRUE" && (
                                                <>


                                                    <input value="✓ Saved to shortlist"
                                                           className="btn btn-primary" type="button"
                                                    />

                                                </>

                                            )}

                                        </form>
                                    </div>
                                </div>


                                <div className="row sta">
                                    <div className="col-sm btn-left">
                                        {user && (
                                            <form>
                                                <Link to='/assistant'>
                                                    <input value="Enquire About this Car"
                                                           className="btn btn-ea" type="button"
                                                    />
                                                </Link>
                                            </form>
                                        )}
                                        {!user && (
                                            <form>
                                                <Link to='/login'>
                                                    <input value="Enquire About this Car"
                                                           className="btn btn-ea" type="button"
                                                    />
                                                </Link>
                                            </form>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* LOGGED IN */}
                        <div className="col-6">
                            <div className="container-login">
                                <div className="row sta">
                                    <div className="col-sm btn-right">
                                        <form>
                                            <Link to='/'>
                                                <input value="Rate Another Car"
                                                       className="btn btn-home" type="button"
                                                />
                                            </Link>
                                        </form>
                                    </div>
                                </div>
                                <div className="row sta">
                                </div>


                            </div>

                        </div>

                    </div>
                    <MOTHistory mots={car}/>
                </div>
            </div>
        </div>
    );
};


export default Score;




