import React, {  useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import { auth, googleAuthProvider} from '../../firebase';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { GoogleLoginButton, FacebookLoginButton } from "react-social-login-buttons";
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

const Login = ({history}) => {

    //LOGIN USES FIREBASE TO PROCESS AUTHENTICATION

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [google, setGoogle] = useState(false);
    const [emailVer, setEmailVer] = useState(false);
    const [loading, setLoading] = useState(false);



    //LOADING BAR ATTRIBUTES
    let [color, setColor] = useState("#ffcc00");
    let [type, setType] = useState("bars");

    //USED TO PREVENT USERS ACCESSING THIS PAGE ONCE LOGGED IN
    const { user } = useSelector((state) => ({ ...state }));

    useEffect(() => {
        if (user && user.token) history.push('/');
    }, [user]);

    let dispatch = useDispatch();



    //HANDLER FOR STANDARD NON-SOCIAL LOG IN.
    const onSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        if (google === true) {

        } else {

            setEmail(email.toLowerCase());


            try {
                const results = await auth.signInWithEmailLink(email, window.location.href);
                setEmailVer(true);
                const { user } = results;
                const idTokenResult = await user.getIdTokenResult();
                let body = await axios.get(`${process.env.REACT_APP_API}api/user/${email}`);

                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: {
                        email: body.data.email,
                        displayName: body.data.displayName,
                        token: idTokenResult.token,
                        checks: body.data.checks
                    }
                })



                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                const email = body.data.email;

                body = JSON.stringify({ email, vehicleId: window.localStorage.getItem('shortlist') });
                try {
                    await axios.post(`${process.env.REACT_APP_API}api/shortlist/add`, body, config);
                } catch (err) {

                }

                window.localStorage.setItem('emailForRegistration', email);
                setEmailVer(true);

                history.push('/');

            } catch (err) {

            }

            function useForceUpdate(){
                const [value, setValue] = useState(0); // integer state
                return () => setValue(value => ++value); // update the state to force render
            }




            try {

                if (emailVer !== true) {


                    const result = await auth.signInWithEmailAndPassword(email, password);

                    const { user } = result;

                    if (user.emailVerified) {
                        const idTokenResult = await user.getIdTokenResult();
                        let body = await axios.get(`${process.env.REACT_APP_API}api/user/${email}`);


                        dispatch({
                            type: 'LOGGED_IN_USER',
                            payload: {
                                email: body.data.email,
                                displayName: body.data.displayName,
                                phoneNumber: body.data.phoneNumber,
                                checks: body.data.checks,
                                token: idTokenResult.token
                            }
                        })

                        const config = {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                        //HANDLER FOR ADDING QUEUED VEHICLE TO SHORTLIST
                        const vehicleId = {
                            _id: window.localStorage.getItem('shortlist')
                        }

                        body = JSON.stringify({
                            email,
                            vehicleId
                        });

                        try {
                            await axios.post(`${process.env.REACT_APP_API}api/shortlist/add`, body, config);
                        } catch (err) {

                        }

                        window.localStorage.setItem('emailForRegistration', email);
                        setEmailVer(true);

                        if (window.localStorage.getItem('shortlist')) {
                            history.push('/score/' + window.localStorage.getItem('score'));
                            window.localStorage.removeItem('shortlist');
                        } else {
                            history.push('/');
                        }
                    }
                    else {

                        toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Please verify your email address before logging in. be sure to check your spam/junk folders too.
                    </div></div>,

                            {
                                position: "top-center",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            });
                        setLoading(false);
                    }
                }
            } catch (error) {
                if (google === true) {

                } else if (error.message === "The password is invalid or the user does not have a password.") {

                    toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Invalid email/password</div></div>,

                        {
                            position: "top-center",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                } else if (error.message === "There is no user record corresponding to this identifier. The user may have been deleted.") {

                    toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">Account does not exist, please sign up before logging in.</div></div>,

                        {
                            position: "top-center",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                } else {

                    toast.error(<div><div className="car-contain"><div className="car-img-error" /></div><div className="car-img-text">{error.message}</div></div>,

                        {
                            position: "top-center",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                }
                setLoading(false);
                // setLoading(false);
            }
        }
    };


    const googleLogin = async () => {
        setLoading(true);
        setGoogle(true);
        auth.signInWithPopup(googleAuthProvider)
            .then(async (result) => {
                const { user } = result;
                const idTokenResult = await user.getIdTokenResult();

                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }


                var tryy = JSON.stringify(user);

                try {
                    await axios.post(`${process.env.REACT_APP_API}api/user/register`, tryy, config);
                }
                catch (error) {
                }


                window.localStorage.setItem('emailForRegistration', user.email);
                let body = await axios.get(`${process.env.REACT_APP_API}api/user/${user.email}`);
                let checks = 0;


                  try {
                      checks = body.data.checks
                  }
                  catch (err) {

                  }

                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: {
                        email: user.email,
                        displayName: user.displayName,
                        phoneNumber: body.data.phoneNumber || "Null",
                        token: idTokenResult.token,
                        checks: checks
                    }
                });

                body = JSON.stringify({ email: user.email,  _id: window.localStorage.getItem('shortlist') });

                try {
                    const res = await axios.post(`${process.env.REACT_APP_API}api/shortlist/add`, body, config);
                }
                catch (err) {

                    }

                window.localStorage.setItem('emailForRegistration', user.email);
                setEmailVer(true);

                    try {
                        const body = await axios.get(`${process.env.REACT_APP_API}api/user/` + window.localStorage.getItem('emailForRegistration'));


                        if (body.data.marketingAuth == true || body.data.marketingAuth == false) {

                        } else {
                            history.push('/marketing');
                        }
                    } catch (error) {

                    }

                        if (window.localStorage.getItem('shortlist')) {
                            history.push('/score/' + window.localStorage.getItem('score'));
                            window.localStorage.removeItem('shortlist');
                        } else {
                            history.push('/');
                        }

            })
            .catch((err) => {
                setLoading(false);
            });
    };

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
}

    return (
<>
                { loading ? (
                <ReactLoading className="loading-screen" type={type} color={color} height={667} width={375} />
                ) : (<></>)}
                <div className="containers">
                <div className="logincontain">
                    <div className="regheader">
                <div class="regsignupl">
                    <Link to='register'>
                    <h1 className="regsignuph">Sign Up</h1>
                    </Link>
                </div>
                <div class="regsigninl">
                    <h1 className="regsignuph">Sign In</h1>
                </div>
            </div>
            <form onSubmit={e =>
                onSubmit(e)}>
                <div className="form-group grey-input">
                    <label>Email</label>
                    <input type="danger"
                    name='email'
                    placeholder="Your Email Address"
                    value={email.toLowerCase()}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    />
                </div>
                <div className="form-group grey-input">
                    <label>Password</label>
                    <input type="password"
                    name='email'
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    />
                </div>
                <input onClick={onSubmit} value="Sign In" type="submit" className="btn btn-info"
                disabled={!email || password.length < 3}
                /><br/>
                <br />
                <GoogleLoginButton onClick={googleLogin} />
                {/*
                <FacebookLoginButton onClick={facebookLogin} />
                */}
                <br /> <br />
                <Link to="/forgot/password">
                Forgotten Your Password?</Link>
            </form>
                </div>

                {/* Seach bar when you remove values doesnt work */}
        </div>
</>
    );
};



export default Login;
