import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/auth/Dashboard';
import Home from './pages/Home';
import Navi from './components/Navi';
import ScrollToTop from './pages/ScrollToTop';
import ForgotPassword from './pages/auth/ForgotPassword';
import Score from './pages/Score';
import Edit from './pages/Edit';
import Marketing from './pages/auth/Marketing';
import Password from './pages/Password';
import Footer from './components/Footer';
import MainImage from './components/MainImage';
import Shortlist from './pages/Shortlist';
import EAHome from './pages/ea/Home';
import MarketingTerms from './pages/docs/MarketingTerms';
import { HashRouter } from 'react-router-dom';
import {toast as toasty, Toaster} from 'react-hot-toast';
import notFound from './pages/404.js';
import Full from './pages/checkout/Full-check';
import Vdi from './pages/checkout/Vdi';

import axios from 'axios';
import {auth} from './firebase'
import { useDispatch } from 'react-redux';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './App.css';
import './App-mobile.css';

const App = () => {

  const dispatch = useDispatch()

    // 1. Retrieve the notifications to display.



  function handleToken(token, addresses) {

  }

  //To check firebase auth state
  useEffect(() => {

    const unsubscribe = auth.onAuthStateChanged(async (user) => {

      if (user && user.emailVerified) {
          try {
            const body = await axios.get(`${process.env.REACT_APP_API}api/user/${user.email}`);
            const idTokenResult = await user.getIdTokenResult()

            dispatch({
              type: 'LOGGED_IN_USER',
              payload: {
                email: user.email,
                displayName: user.displayName || body.data.displayName,
                phoneNumber: user.phoneNumber || body.data.phoneNumber,
                checks: body.data.checks,
                token: idTokenResult.token,
              }
            })
          } catch (err) {

          }
        }
    })
    return () => unsubscribe();
  }, );

  return (
      <BrowserRouter>
      <MainImage />
      <Navi />
      <Toaster />
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
      />
        <ScrollToTop />
      <Switch>

      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route path="/forgot/password" component={ForgotPassword} />
        <Route path="/score/:id" component={Score} />
        <Route exact path="/marketing" component={Marketing} />
        <Route path="/edit" component={Edit} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/assistant" component={EAHome} />
        <Route path="/checkout" component={Full} />
        <Route path="/vdi-check" component={Vdi} />
        <Route path="/password" component={Password} />
        <Route path="/shortlist" component={Shortlist} />

        <Route path="/marketing-terms" component={MarketingTerms} />
        <Route path='*' exact={true} component={notFound} />

      </Switch>

      <Footer />
      </BrowserRouter>
  );
}

export default App;
