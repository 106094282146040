import React from 'react';
import styled from 'styled-components';

const steps = [
    'Search used cars',
    'Find one you like the look of',
    'Enter the Reg Number on CarXpert',
    'CarXpert rates the car',
    'If it looks good, add it to your shortlist',
    'CarXpert gives you the questions to ask when you enquire about a car',
    'You decide if you want to view the car',
]

const stepBackground = 'rgb(0, 0, 50)';
const arrowWidth = 30;

const Section = styled.div`
  text-align: center;
  margin: 40px 0;
`;

const Step = styled.div`
  text-align: center;
  width: 50%;
  margin: 30px auto 50px;
  padding: 20px;
  background: ${stepBackground};
  color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 1px 5px #333;
  font-size: 18px;
  position: relative;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 14px;
  }
`;

const StepArrow = styled.div`
  width: ${arrowWidth}px;
  height: ${arrowWidth}px;
  background: ${stepBackground};
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -${arrowWidth/2}px;
  box-shadow: 0 1px 5px #333;
  
  &:before{
    content: '';
    border-left: ${arrowWidth}px solid transparent;
    border-right: ${arrowWidth}px solid transparent;
    border-top: ${arrowWidth}px solid ${stepBackground};
    position: absolute;
    bottom: -${arrowWidth}px;
    left: -${arrowWidth/2}px;
  }
`;

const HowItWorks = () => {
    const renderStep = (step, i) => {
        return (
            <Step key={`how-it-works-step_${i}`}>
                <span>{step}</span>
                {i < steps.length - 1 ? <StepArrow /> : ''}
            </Step>
        );
    }

    return (
        <Section>
            <h2>How it Works</h2>
            {steps.map(renderStep)}
        </Section>
    );
};

export default HowItWorks;
