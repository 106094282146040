import {Accordion, Card} from "react-bootstrap";
import React from "react";
import styled from 'styled-components';

const AccordionStyled = styled.div`
  .card{
    border: none !important;
    border-radius: 10px !important;
  }

  .card-header{
    background-color: #ffcc00 !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h2{
      font-size: 22px;
      margin: 0;
      line-height: 35px;
    }
  }

  .mot-history-padding{
    padding: 10px !important;
    font-size: 12px !important;
    font-family: 'Overpass',sans-serif;
    line-height: 1.5;
    
    &:nth-child(odd) {
      background-color: #f4f4f4 !important;
    }
    
    h4{
      margin: 0 !important;
      line-height: inherit;
      font-weight: 600;
        min-width: 60px;
    }
  }
  
  strong{
    margin: 0;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    .card{
      border-radius: 5px !important;
      margin-top: 5px;
    }

    .card-header{
      padding: 0 30px !important;
      
      h2{
        font-size: 12px;
      }
    }

    .mot-history-padding {
      font-size: 8px !important;
    }
  }
`;

const MOTHistory = (props) => {
    const { mots } = props;

    const renderComments = (comments) => (
        <>
            <div className="row">
                <div className="col-12">
                    <strong>
                        Advisory Notice(s)
                    </strong>
                </div>
            </div>

            {comments.map((comment) => (
                <div className="row">
                    <div className="col-4 col-md-2">
                        {comment.type === "USER ENTERED" ? 'ADVISORY' : ''}
                        {comment.type === "PRS" ? 'FAILURE' : ''}
                        {comment.type !== "USER ENTERED" && comment.type !== "PRS" ? comment.type : ''}
                    </div>
                    <div className="col-8 col-md-10">
                        <div className="circle-yellow"></div>
                        <div>{comment.text}</div>
                    </div>
                </div>
            ))}
        </>
    );

    const renderMotItem = (mot) => (
        <div className="mot-history-padding">
            <div className="row">
                <div className="col-12">
                    <strong>{mot.completedDate}</strong>
                </div>
            </div>

            <div className="row">
                <div className="col-3 col-md-2">
                    <h4 className={mot.testResult === 'PASSED' ? 'result-pass' : 'result'}>
                        <strong>{mot.testResult}</strong>
                    </h4>
                </div>
                <div className="col-4 col-md-5">
                    <div className="mot-test-font">
                        <strong>Mileage: {mot.odometerValue} {mot.odometerUnit}</strong>
                    </div>
                </div>
                <div className="col-5 text-right">
                    <strong>Mot Test: {mot.motTestNumber}</strong>
                </div>
            </div>
            { mot.rfrAndComments && mot.rfrAndComments.length ? renderComments(mot.rfrAndComments) : ''}
        </div>
    );

    return (
        <AccordionStyled>
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                        <h2>MOT History</h2>
                        <div className="arrow-down"></div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {mots.map(renderMotItem)}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </AccordionStyled>
    );
};

export default MOTHistory;


