import React, {Fragment, useState} from 'react';
import { Link, Element } from 'react-scroll';
import { Link as RoutLink } from 'react-router-dom';
import _ from 'lodash';
import enquiryData from '../../data/enquiryAssistant.json';

const groupQuestionsByStage = (data) => (_.chain(data)
        .groupBy("stage")
        .map((value, key) => ({ stage: key, questions: value }))
        .value())

const EAHome = () => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([enquiryData.data[0]]);

    const selectOption = (questionId, selectedOptionValue) => {
        const selectedOptionsUpdate = [...selectedOptions];

        const index = selectedOptionsUpdate.findIndex((i) => i.id === questionId);

        if (index === -1) {
            selectedOptionsUpdate.push(Object.assign({
                id: questionId,
                value: selectedOptionValue
            }))
            setSelectedOptions(selectedOptionsUpdate);
        } else {
            selectedOptionsUpdate[index].value = selectedOptionValue;
            setSelectedOptions(selectedOptionsUpdate.slice(0, index + 1));
        }


        filterQuestions(selectedOptionsUpdate);
    };

    const filterQuestions = (selected) => {
        const filteredQuestionsUpdated = enquiryData.data.filter((question) => {
            const numberOfDependencies = question.dependOn ? question.dependOn.length : 0;

            if (!numberOfDependencies) {
                return true
            } else {
                const numberOfSuccessDependencies = question.dependOn.reduce((counter, dep)  => {
                    if (dep.optionId === 'any') {
                        return _.some(selected, {id: dep.questionId}) ? counter + 1 : counter;
                    } else {
                        return _.some(selected, {
                            id: dep.questionId,
                            value: dep.optionId,
                        }) ? counter + 1 : counter;
                    }
                }, 0)

                return _.isEqual(numberOfSuccessDependencies, numberOfDependencies);
            }
        })

        setFilteredQuestions(filteredQuestionsUpdated);
    }

    const renderQuestion = (question) => {
        const selectedOption = selectedOptions.find((option) => option.id === question.id);

        const getDescriptionText = () => {
            const optionObject = selectedOption && question.options.find((o) => selectedOption.value === o.value);
            const pClass = optionObject && optionObject.isDealBreaker ? 'red' : '';
            let description = question.defaultDescription;

            if (selectedOption && optionObject) {
                description = optionObject.description;
            }

            return (<p className={`ea-font ${pClass}`}>{description}</p>);
        };

        return (
            <div className="row prehei enquiry-assistant" key={`question_${question.id}`}>
                <div className="col">
                    <p className="ea-font">{question.title}</p>
                </div>
                <div className="col">
                    <div className="enquiry-assistant-row">
                        {question.options.map((option) => (
                            <div className="col" key={`option_${option.name}_for_question_${question.id}`}>
                                <Link activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} >
                                    <input type="button"
                                           onClick={() => { selectOption(question.id, option.value); }}
                                           value={option.name}
                                           className={selectedOption && selectedOption.value === option.value ? "btn prebutton" : "btn prebutton1"} />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col">
                    {getDescriptionText()}
                </div>
            </div>
        );
    }

    const renderQuestions = () => {
        const renderStageTitle = (stageObject) => (!!parseInt(stageObject.stage, 10) ? (
                <div className="scoreheadd">
                    <h4 className="prechecks">
                        Stage {stageObject.stage}/{_.size(_.countBy(filteredQuestions, "stage")) - 1}
                    </h4>
                </div>
            ) : '')

        const canRenderNextQuestion = (currentQuestion) => {
            const indexOfCurrentQuestion = filteredQuestions.findIndex((filteredQuestion) => (
                currentQuestion.id === filteredQuestion.id
            ));

            if(indexOfCurrentQuestion === 0) {
                return true;
            }

            const previousQuestion = filteredQuestions[indexOfCurrentQuestion - 1];
            const previousSelectedOption = selectedOptions.find((option) => (
                option.id === previousQuestion.id
            ));

            if (!previousSelectedOption) {
                return false;
            }

            const isPreviousOptionDealBreaker = previousQuestion.options.find((o) =>
                o.value === previousSelectedOption.value);

            return !isPreviousOptionDealBreaker.isDealBreaker;
        }

        const renderButtons = () => {
            return (
                <div className="text-center">
                    <RoutLink to='/'>
                        <input className="btn btn-home" type="button" value="Go to Main Menu" />
                    </RoutLink>
                    <RoutLink to='/shortlist'>
                        <input className="btn btn-home" type="button" value="Go to Shortlist" />
                    </RoutLink>
                </div>
            );
        }

        const canDisplayButtons = (question) => {
            const answer = selectedOptions.find(s => s.id === question.id);
            const questionOption = answer && question.options.find(o => o.value === answer.value);

            return questionOption && questionOption.isDealBreaker;
        }

        const renderFinishText = (stage, question) => {
            const isFirstStage = parseInt(stage, 10) === 0;
            const isLastQuestionInList = question.id === filteredQuestions[filteredQuestions.length -1].id;
            const isQuestionAnswered = selectedOptions.find(s => s.id === question.id);

            return !isFirstStage && isLastQuestionInList && isQuestionAnswered ? (
                <div>
                    <p>Congratulations, you've now got answers to all
                        the questions you need and can decide if you
                        want to view the car</p>
                    {renderButtons()}
                </div>

            ) : '';
        }

        return (
            <form>
                <div className="row">
                    <div className="col-sm">
                        {groupQuestionsByStage(filteredQuestions).map((stageObject) => (
                            <div key={`stage_${stageObject.stage}`}>
                                {stageObject.questions.map((question, i) => (
                                    canRenderNextQuestion(question) ? (
                                        <div key={`stage_${stageObject.stage}_title_${i}`}>
                                            {i === 0 ? renderStageTitle(stageObject) : ''}
                                            {renderQuestion(question)}
                                            {renderFinishText(stageObject.stage, question)}
                                            {canDisplayButtons(question) ? renderButtons() : ''}
                                        </div>
                                    ) : ''))}
                            </div>
                        ))}
                    </div>
                </div>
            </form>
        );
    }

    return (
        <Fragment>
            <section className='landing'>
                <div className="container">
                    <div className='precheckcontainer'>
                        <div className="scorehead">
                            <h1 className="prechecks">Enquiry Assistant</h1>
                        </div>
                        <div className="precheckcontain">
                            <h6>Welcome to the CarXpert Enquiry Assistant</h6>
                            <br></br>
                            <p>There are quite a few questions
                                in the Enquiry Assistant as there are many things
                                that can catch you out when buying a used car. The
                                Enquiry Assistant will limit the number of questions
                                as much as possible and will also show you which
                                questions are most important. The aim is to help make
                                sure you don't waste time and money travelling to
                                view a car that isn't as described - or even worse,
                                ending up purchasing one. Time invested in some questions
                                could save you a lot of money.</p>
                            <br></br>
                            <p>Before you start to contact the seller, please submit the form
                                below so we can generate your questions!</p>

                            <div className="container">
                                {renderQuestions()}
                                <Element name="test1" className="element" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};


export default EAHome;
