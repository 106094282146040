import React, {  useState, useEffect } from 'react';
import '../App.css';





const notFound = ({ history }) => {

    
      

  return (
      <div className="logincontain">
    <a className="back-btn" onClick={() => (history.goBack())}>Go Back</a>
          <div className="header"></div>
          <h1> 404 PAGE NOT FOUND</h1>
          <p>Try going back?</p>
                 <a className="back-btn" onClick={() => (history.goBack())}>Go Back</a>
  </div>
  );
}

export default notFound;